/* eslint-disable prettier/prettier */
import Asset from '@components/Asset/Asset';
import GuardianFoundPopup from '@components/GuardianFound/GuardianFoundPopup';
import Logo from '@components/Logo/Logo';
import React from 'react';
import { Link } from 'react-router-dom';
import client from '../../core/feathers';
import {
  getPlayerAvatar,
  isPlayer,
  loadPlayer,
  savePlayer,
  setAvatar,
} from '../../functions/players';
import AvatarPage from './AvatarPage';
import './CustomisePage.scss';
import switchBackground from '@functions/switchBackground';

export default class CustomisePage extends React.Component {
  constructor(props) {
    super(props);

    const showScan = localStorage.getItem('showScannerBtn');

    this.state = {
      loading: false,
      player: false,
      maps: [],
      trades: [],
      showAvatarPage: false,
      showScannerBtn: showScan,
    };
  }

  componentWillUnmount() {
    switchBackground('none');
  }

  componentDidMount() {
    switchBackground('orange');
    this.getPlayer(this.getMaps);
    setTimeout(() => {
      localStorage.setItem('showScannerBtn', 'no');
    }, 500);
  }

  getItems = () => {
    const { player } = this.state;
    //get all the players trades
    client
      .service('trades')
      .find({ query: { playerId: player._id } })
      .then((trades) => {
        this.setState({ trades: trades.data });
      })
      .catch((error) => {
        console.log('no trades', error);
      });
  };

  getMaps = () => {
    client
      .service('maps')
      .find()
      .then((maps) => {
        this.setState({ maps: maps.data }, this.getItems);
      })
      .catch((error) => {
        console.log('no maps?', error);
      });
  };

  getPlayer = (f) => {
    const player = isPlayer();
    if (!player) return console.log('not logged in (no player found)');
    loadPlayer((p) => {
      if (!p) return console.log('error fetching player');
      this.setState({ player: p, loading: false }, () => {
        f();
      });
    });
  };

  setAvatar = (code, hasFound) => {
    if (!hasFound) return;
    let { player } = this.state;
    player = setAvatar(player, code);
    savePlayer(player, (p) => {
      this.setState({ player: p });
    });
  };

  checkStage = (marker) => {
    const { player } = this.state;
    if (player.trades && player.trades.length > 0) {
      for (let i = 0; i < player.trades.length; i++) {
        if (player.trades[i].marker == marker._id) {
          return true;
        }
      }
    }

    return false;
  };

  buildMarkers = (map) => {
    const { player } = this.state;
    return map.codes.map((code, i) => {
      const isAvatar = player.avatar && player.avatar._id == code._id;
      const isAvatarClass = isAvatar ? 'current' : '';
      const hasFound = this.checkStage(code);
      const assetShow = hasFound
        ? code.characterObj.assetAvatarSmall
        : '/images/avatarHidden.svg';
      return (
        <div className={`zone--markers-marker ${isAvatarClass}`} key={i}>
          <button
            onClick={() => {
              this.setAvatar(code, hasFound);
            }}
          >
            <Asset src={assetShow} />
          </button>
        </div>
      );
    });
  };

  buildZones = () => {
    const { maps, player } = this.state;
    if (!player.unlocked) return [];

    //build correct order
    const _maps = [];
    for (let i = 0; i < player.unlocked.length; i++) {
      for (let o = 0; o < maps.length; o++) {
        if (maps[o]._id == player.unlocked[i]) {
          _maps.push(maps[o]);
        }
      }
    }

    return _maps.map((map, i) => {
      if (!player.unlocked) return false;
      if (!player.unlocked.includes(map._id)) return false;
      return (
        <div className="zone" key={i}>
          <div className="zone--title">{map.name} Guardians</div>
          <div className="zone--markers">{this.buildMarkers(map)}</div>
        </div>
      );
    });
  };

  buildCollectables = () => {
    const { player } = this.state;
    if (!player || !player.tradeable) return [];
    return player.tradeable.map((item, i) => {
      return (
        <div className="tradeable" key={i}>
          <Asset src={item.asset} />
        </div>
      );
    });
  };

  render() {
    const { loading, player, showAvatarPage, showScannerBtn } = this.state;
    const avatar = getPlayerAvatar(player);
    const showTitle = avatar.characterObj.title ? true : false;
    if (loading) return <div className="loading">loading...</div>;

    return (
      <>
        <GuardianFoundPopup />
        <AvatarPage
          player={player}
          show={showAvatarPage}
          close={() => {
            this.setState({ showAvatarPage: false });
          }}
        />
        <div className="page page-customise background orange">
          <Logo sticky={true} />
          <div className="button-container left" style={{ zIndex: 13 }}>
            <Link className="button green no-text" to="/menu">
              <span className="back" />
            </Link>
          </div>
          <div className="player-info">
            <div className="player--avatar">
              <img src={avatar.characterObj.assetAvatarSmall} />
            </div>
            <div className="player--name">
              {showTitle && (
                <div className="title">{avatar.characterObj.title}</div>
              )}
              <div className="inside">{player.name}</div>
              {showTitle && (
                <div className="openAvatar">
                  <button
                    onClick={() => {
                      this.setState({ showAvatarPage: true });
                    }}
                    className="button white no-text"
                  >
                    <span className="zoom" />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="page-customise--inside colour-blue">
            <div className="zone--title">
              My <span className="colour-orange bold">gifts</span>
            </div>
            <div className="collectables">{this.buildCollectables()}</div>
            <div className="zones">{this.buildZones()}</div>
          </div>
          {showScannerBtn == 'yes' && (
            <div className="button-container bottom fadeInUp delay-20">
              <Link className="button" to="/play">
                Back to Scanner
              </Link>
            </div>
          )}
        </div>
      </>
    );
  }
}
