/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import MediaBrowser from '@pages/Admin/Media/components/MediaBrowser/MediaBrowser';
import React from 'react';

export default class MultiAssetSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBrowser: false,
    };
  }

  renderChosen = () => {
    return this.props.items.map((item, i) => {
      return (
        <div className="object-selectable" key={i}>
          <div className="inside">
            <div className="object-name column">
              <img src={item} />
            </div>

            <div className="object-options column">
              <button
                className="purple"
                onClick={() => {
                  this.removeAsset(i);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      );
    });
  };

  removeAsset = (i) => {
    let { items } = this.props;
    items.splice(i, 1);
    this.props.onChange(items);
  };

  hideMediaBrowser = () => {
    this.setState({ showBrowser: false });
  };

  addMedia = (mediaItem) => {
    //check it's not already in there
    let { items } = this.props;
    for (let i = 0; i < items.length; i++) {
      if (items[i] == mediaItem.url) {
        return;
      }
    }

    items.push(mediaItem.url);
    this.props.onChange(items);
  };

  render() {
    const { showBrowser } = this.state;
    return (
      <div className="multiAssetSelector">
        <div className="objectList">
          <div className="ob-content">
            <div className="ob-content--content">{this.renderChosen()}</div>
          </div>
        </div>
        <button
          onClick={() => {
            this.setState({ showBrowser: true });
          }}
        >
          Select Media
        </button>
        <MediaBrowser
          show={showBrowser}
          hide={this.hideMediaBrowser}
          select={(img) => {
            this.addMedia(img);
          }}
        />
      </div>
    );
  }
}
