import React from 'react';
import client from '../../../../core/feathers';

class UserList extends React.Component<any, any> {
  deleteUser = (user: any) => {
    const users = client.service('users');
    users.remove(user._id);
  };

  buildList = () => {
    const items: any = [];
    const { users } = this.props;
    users.forEach((item: any, n: number) => {
      items.push(
        <div key={n} className="listItem user">
          {item.email}{' '}
          <button
            onClick={() => {
              this.deleteUser(item);
            }}
          >
            delete
          </button>
        </div>
      );
    });
    return items;
  };
  render() {
    const items = this.buildList();
    return <div className="users">{items}</div>;
  }
}

export default UserList;
