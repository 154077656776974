/* eslint-disable prettier/prettier */
import React from 'react';
import './FoundTransition.scss';

export default class FoundTransition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    this.setListener();
  }

  setListener = () => {
    window.addEventListener(
      'animateOut',
      (e) => {
        this.setState({ show: true }, () => {
          setTimeout(() => {
            this.close();
          }, 2500);
          setTimeout(() => {
            this.setState({ show: false });
          }, 5100);
        });
      },
      false
    );
  };

  close = () => {
    const event = new Event('foundRedirect');
    window.dispatchEvent(event);
  };

  render() {
    const { show } = this.state;
    const showClass = show ? 'show' : 'hidden';
    return (
      <div className={`foundTransition ${showClass}`}>
        <div className="inside">
          <div className="ball ball-1" />
          <div className="ball ball-2" />
          <div className="ball ball-3" />
          <div className="ball ball-4" />
          <div className="anim-logo" />
          <div className="bg-anim" />
        </div>
      </div>
    );
  }
}
