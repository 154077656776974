/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Login from '../../core/login';
import { MainAdminContainer } from './MainAdmin.styled';
import client from '../../core/feathers';
import MainAdminHeader from './MainAdminHeader';

interface IState {
  login?: any;
  user?: any;
}

export class MainAdmin extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      login: false,
    };
  }

  componentDidMount() {
    client.authenticate().catch(() => this.setState({ login: null }));
    client.on('authenticated', (login) => {
      // Get all users and messages
      const user = login.user;
      this.setState({ login, user });
    });
  }

  render() {
    const { children } = this.props;
    const { login } = this.state;
    return (
      <MainAdminContainer data-testid="Main">
        {login && (
          <div className="admin-logged-in">
            <MainAdminHeader />
            {children}
          </div>
        )}
        {!login && <Login />}
      </MainAdminContainer>
    );
  }
}
