/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import preloadImage from '../../functions/preload';
import api from '../../functions/getApi';
import { setSettings } from '../../functions/settings';
import './LoadAssets.scss';
import client from '../../core/feathers';

export default class LoadAssets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      imagesLoaded: 0,
      progress: 0,
      total: 0,
      needed: 0,
      srcNeeded: [],
    };
  }

  componentDidMount() {
    //load the images json
    this.fetchSettings();
    fetch(`${api}/getImagesJson`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.preloadImages(json);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  preloadImages = (json) => {
    client
      .service('settings')
      .find({
        query: {
          name: 'preload',
        },
      })
      .then((data) => {
        if (data.data.length <= 0) {
          this.beginPreload([], json);
          return;
        } else {
          this.beginPreload(data.data[0].assets, json);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  beginPreload = (preload, json) => {
    let needed = 0;
    let srcNeeded = [];

    if (preload.length > 0) {
      needed = preload.length;
      for (let p = 0; p < preload.length; p++) {
        json.unshift(preload[p]);
        srcNeeded.push({
          src: preload[p],
          loaded: false,
        });
      }
    }

    if (!json) this.complete();

    let t = 0;
    let imgA = [];
    //total count = be sure to exclude duplicates
    for (let i = 0; i < json.length; i++) {
      if (!imgA.includes(json[i])) {
        t++;
        imgA.push(json[i]);
      }
    }

    if (needed == 0) needed = json.length;

    this.setState({ total: t, needed, srcNeeded }, () => {
      json.forEach((element) => {
        preloadImage(element, this.imageLoad);
      });
    });
  };

  imageLoad = (src) => {
    let { imagesLoaded, progress, needed, srcNeeded } = this.state;
    for (let i = 0; i < srcNeeded.length; i++) {
      if (srcNeeded[i].src == src) {
        imagesLoaded++;
      }
    }
    progress = (imagesLoaded / needed) * 100;
    this.setState({ imagesLoaded, progress }, () => {
      this.checkDone();
    });
  };

  checkDone = () => {
    const { progress, settings } = this.state;
    if (settings && progress >= 99.9) {
      this.complete();
    }
  };

  complete = () => {
    const { animate } = this.state;
    if (animate) return;
    this.setState({ complete: true, progress: 100, animate: true }, () => {
      setTimeout(() => {
        this.props.onComplete();
      }, 3000);
    });
  };

  fetchSettings = () => {
    fetch(`${api}/getSettings`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.setState({ settings: json }, () => {
          setSettings(json);
          this.checkDone();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { progress, imagesLoaded, needed, animate } = this.state;
    const progressRounded = Math.ceil(progress);
    const animateClass = animate ? 'animOut' : '';
    return (
      <div className={`loadAssets ${animateClass}`}>
        <div className="bg-anim" />
        <div className="inside">
          <div className="content">
            <div className="anim-logo" />
            <div className="loadingBar">
              <div
                className="loadingBar--inside"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="progress" style={{ left: `${progress}%` }}>
              loading... {progressRounded}% ({imagesLoaded}/{needed})
            </div>
          </div>
        </div>
      </div>
    );
  }
}
