/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import MultiObjectSelector from '@components/MultiObjectSelector/MultiObjectSelector';
import MediaBrowser from '@pages/Admin/Media/components/MediaBrowser/MediaBrowser';
import React from 'react';
import { Redirect } from 'react-router';
import client from '../../../../core/feathers';
import qrcode from 'qrcode-generator';
import { DefaultEditor } from 'react-simple-wysiwyg';

export default class NewMapPage extends React.Component {
  constructor(props) {
    super(props);
    const edit =
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : false;
    this.state = {
      showBrowser: false,
      redirect: false,
      edit,
      formValues: {
        name: '',
        asset: false,
        codeItems: [],
        infoText: '',
      },
      notFound: false,
      loading: edit ? true : false,
      saved: false,
    };
  }

  componentDidMount() {
    const { edit } = this.state;
    if (edit) {
      this.loadObject();
    }
  }

  generateQRcode = () => {
    const { edit, formValues } = this.state;
    const typeNumber = 0;
    const errorCorrectionLevel = 'L';
    const qr = qrcode(typeNumber, errorCorrectionLevel);
    const uri = window.location.origin + '/start/' + formValues.uri;
    qr.addData(uri);
    qr.make();
    this.setState({ qrCodeGenerated: qr.createImgTag() });
  };

  loadObject = () => {
    const { edit } = this.state;
    const items = client.service('maps');
    Promise.all([items.get(edit)])
      .then(([itemData]) => {
        const item = itemData;
        // Once both return, update the state
        this.setState({ formValues: item, loading: false }, () => {
          this.generateQRcode();
        });
      })
      .catch((error) => {
        this.setState({ loading: false, notFound: true });
      });
  };

  setValue = (type, value) => {
    let { formValues } = this.state;
    formValues[type] = value;
    this.setState({ formValues });
  };

  showMediaBrowser = (type) => {
    this.setState({ showBrowser: true, mediaChange: type });
  };

  hideMediaBrowser = () => {
    this.setState({ showBrowser: false });
  };

  checkValues = (vals) => {
    if (!vals.asset || !vals.name || vals.name == '') return false;

    return true;
  };

  save = () => {
    const { edit } = this.state;
    if (edit) return this.update();

    return this.create();
  };

  update = () => {
    const { formValues } = this.state;
    if (!this.checkValues(formValues)) return false;
    return client
      .service('maps')
      .update(formValues._id, formValues)
      .then(() => {
        //reset
        this.setState({
          saved: true,
        });
      });
  };

  create = () => {
    const { formValues } = this.state;
    if (!this.checkValues(formValues)) return false;
    return client
      .service('maps')
      .create(formValues)
      .then(() => {
        //reset
        this.setState({
          redirect: true,
        });
      });
  };

  updateCodeItems = (items, refOb) => {
    const { formValues } = this.state;
    formValues.codeItems = items;
    this.setState({ formValues });
  };

  render() {
    const {
      formValues,
      showBrowser,
      redirect,
      loading,
      notFound,
      edit,
      saved,
      qrCodeGenerated,
    } = this.state;
    if (redirect) {
      return <Redirect to="/admin/maps" />;
    }
    if (notFound) {
      return <div className="notFound">404 not found</div>;
    }
    if (loading) {
      return <div className="loading">Loading...</div>;
    }

    const qrCodeUri = window.location.origin + '/start/' + formValues.uri;
    return (
      <div>
        {edit && <h1>Edit {formValues.name}</h1>}
        {!edit && <h1>Add New Map</h1>}
        {qrCodeGenerated && (
          <div className="qr-holder">
            <div
              id="qrcode"
              dangerouslySetInnerHTML={{ __html: qrCodeGenerated }}
            />
            <div className="url">{qrCodeUri}</div>
          </div>
        )}
        {saved && <div className="formNote positive">Map saved</div>}
        <div className="form">
          <div className="formItem">
            <label htmlFor="name">Map Name</label>
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={(e) => {
                this.setValue('name', e.target.value);
              }}
            />
          </div>
          <div className="formItem">
            <label htmlFor="uri">Map uri</label>
            <input
              type="text"
              name="uri"
              value={formValues.uri}
              onChange={(e) => {
                this.setValue('uri', e.target.value);
              }}
            />
          </div>
          <div className="formItem">
            <label htmlFor="allfoundtitletext">All found Title</label>
            <input
              type="text"
              name="allfoundtitletext"
              value={formValues.allfoundtitletext}
              onChange={(e) => {
                this.setValue('allfoundtitletext', e.target.value);
              }}
            />
          </div>
          <div className="formItem">
            <label htmlFor="allfoundtitle">All found title hex</label>
            <input
              type="text"
              name="allfoundtitle"
              value={formValues.allfoundtitle}
              onChange={(e) => {
                this.setValue('allfoundtitle', e.target.value);
              }}
            />
          </div>
          <div className="formItem">
            <label htmlFor="allfoundbg">All found background hex</label>
            <input
              type="text"
              name="allfoundbg"
              value={formValues.allfoundbg}
              onChange={(e) => {
                this.setValue('allfoundbg', e.target.value);
              }}
            />
          </div>
          <div className="formItem">
            <label htmlFor="name">All found asset</label>
            <div className="formItem--asset">
              <div>{formValues.asset && <img src={formValues.asset} />}</div>
              <div>
                <button
                  onClick={() => {
                    this.showMediaBrowser('asset');
                  }}
                >
                  change
                </button>
              </div>
            </div>
          </div>
          <div className="formItem">
            <label htmlFor="name">Map Asset</label>
            <div className="formItem--asset">
              <div>
                {formValues.mapAsset && <img src={formValues.mapAsset} />}
              </div>
              <div>
                <button
                  onClick={() => {
                    this.showMediaBrowser('mapAsset');
                  }}
                >
                  change
                </button>
              </div>
            </div>
          </div>
          <div className="formItem">
            <label htmlFor="name">Initial Asset</label>
            <div className="formItem--asset">
              <div>
                {formValues.initialAsset && (
                  <img src={formValues.initialAsset} />
                )}
              </div>
              <div>
                <button
                  onClick={() => {
                    this.showMediaBrowser('initialAsset');
                  }}
                >
                  change
                </button>
              </div>
            </div>
          </div>
          <MediaBrowser
            show={showBrowser}
            hide={this.hideMediaBrowser}
            select={(img) => {
              const { mediaChange } = this.state;
              this.setValue(mediaChange, img.url);
            }}
          />
          <div className="formItem">
            <label htmlFor="name">Selected QR Codes</label>
            <MultiObjectSelector
              type="codes"
              items={formValues.codeItems}
              updateItems={this.updateCodeItems}
            />
          </div>
        </div>
        <div className="formItem">
          <label htmlFor="name">Zone info (zones page)</label>
          <DefaultEditor
            type="text"
            name="infoText"
            value={formValues.infoText}
            onChange={(e) => {
              this.setValue('infoText', e.target.value);
            }}
          />
        </div>
        <div className="formItem formSubmit">
          <button onClick={this.save}>Save</button>
        </div>
      </div>
    );
  }
}
