/* eslint-disable prettier/prettier */
export default function preloadImage(img, doneFunc) {
  if (document.images) {
    if (window.preloadedImages) {
      if (window.preloadedImages.includes(img)) {
        return;
      }
    } else {
      window.preloadedImages = [];
      window.preloadedImagesCache = [];
    }
    var img1 = new Image();
    img1.onload = function () {
      doneFunc(img);
    };
    img1.onerror = function () {
      doneFunc(img);
    };
    img1.src = img;
    window.preloadedImages.push(img);
    window.preloadedImagesCache.push(img1);
  }
}
