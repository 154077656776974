/* eslint-disable prettier/prettier */
export default function mapDataToReferences(references, data, fullObjects) {
  if (!references || references.length <= 0) return data;
  return references.map((ref, index) => {
    let ob = null;
    data.forEach((item) => {
      let _idStr = '$id';
      if (fullObjects) _idStr = '_id';
      if (item._id == ref[_idStr]) ob = item;
    });

    if (!ob) return;
    return ob;
  });
}
