/* eslint-disable prettier/prettier */
import React from 'react';
import { isPlayer, loadPlayer } from '../../functions/players';
import client from '../../core/feathers';
import './Allfound.scss';
export default class AllFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      selectedMap: null,
    };
  }
  componentDidMount() {
    this.getPlayer(this.getMaps);
  }

  getTrades = () => {
    //check all the maps
    client
      .service('codes')
      .find()
      .then((data) => {
        this.checkCodes(data);
      })
      .catch((error) => {
        //no codes found
      });
  };

  getMaps = () => {
    client
      .service('maps')
      .find()
      .then((maps) => {
        this.setState({ maps: maps.data }, this.checkCodes);
      })
      .catch((error) => {
        console.log('no maps?', error);
      });
  };

  getPlayer = (f) => {
    const player = isPlayer();
    if (!player) return console.log('not logged in (no player found)');
    loadPlayer((p) => {
      if (!p) return console.log('error fetching player');
      this.setState({ player: p, loading: false }, () => {
        f();
      });
    });
  };

  foundAll = (map) => {
    localStorage.setItem(`allFound__${map._id}`, true);
    this.setState({ popup: true, selectedMap: map });
  };

  checkCodes = () => {
    const { maps } = this.state;
    maps.forEach((map, index) => {
      const checked = localStorage.getItem(`allFound__${map._id}`);
      if (checked) return;

      if (!map.codes) return;
      let found = 0;
      map.codes.forEach((code, i) => {
        if (this.checkCode(code)) {
          found++;
        }
      });
      if (found >= map.codes.length) {
        //tis found!
        this.foundAll(map);
      }
    });
  };

  checkCode = (marker) => {
    //check on trades
    const { player } = this.state;
    if (player.trades && player.trades.length > 0) {
      for (let i = 0; i < player.trades.length; i++) {
        if (player.trades[i].marker == marker._id) {
          return true;
        }
      }
    }

    return false;
  };

  render() {
    const { popup, selectedMap } = this.state;
    if (!popup) return <></>;
    return (
      <div className="popup popup--allfound">
        <div className="inside" style={{ background: selectedMap.allfoundbg }}>
          <div className="colour-white krana font-30 bold text-transform-upper">
            Congratulations on finding all the
          </div>
          <div
            className="krana font-56 bold text-transform-upper"
            style={{ color: selectedMap.allfoundtitle }}
          >
            {selectedMap.allfoundtitletext}
          </div>
          <div className="foundAllImg">
            <img src={selectedMap.asset} />
          </div>
        </div>
        <button
          className="button"
          onClick={() => {
            this.setState({ popup: false });
          }}
        >
          Yay!
        </button>
      </div>
    );
  }
}
