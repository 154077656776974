/* eslint-disable prettier/prettier */
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import QrReader from 'react-qr-reader';
import Logo from '@components/Logo/Logo';
import './Play.scss';

export default class PlayPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
      markerFound: false,
      canFind: true,
      cameraError: false,
    };
  }

  markerFound = (data) => {
    const { canFind } = this.state;
    if (!canFind) return;
    if (!data) return;
    let uri = data;
    if (!uri.includes('/')) return console.log('invalid QR code');
    uri = /[^/]*$/.exec(uri)[0];
    this.setState({ redirect: false, markerFound: uri, canFind: false }, () => {
      //do the slick animation

      window.addEventListener(
        'foundRedirect',
        (e) => {
          this.setState({ redirect: true });
        },
        true
      );

      const event = new Event('animateOut');

      // Dispatch the event.
      window.dispatchEvent(event);
    });
  };

  markerLost = (e) => {
    //marker is lost
    this.setState({ cameraError: true });
  };

  test = () => {
    this.markerFound('https://yawhat.com/607fef7e34ccbc3a726d59ee');
  };

  render() {
    const { loading, redirect, markerFound, cameraError } = this.state;
    const showNoCam = cameraError ? 'show' : 'hide';
    if (redirect) {
      return <Redirect to={`/play/${markerFound}`} />;
    }
    if (loading) {
      return <div className="loading">loading...</div>;
    }
    return (
      <div className="page-play">
        <Logo colour="white" />
        <div className="button-container left z-15">
          <Link to="/menu" className="button green arrow-left no-text">
            <span className="back" />
          </Link>
        </div>
        <div className={`no-camera ${showNoCam}`}>
          <div>
            <h2>Camera is disabled.</h2>
            Please enable camera permissions in your settings
          </div>
        </div>
        <div className="qr-reader">
          <QrReader
            delay={300}
            onError={this.markerLost}
            onScan={this.markerFound}
            style={{ width: '100%', height: '100vh' }}
          />
        </div>
      </div>
    );
  }
}
