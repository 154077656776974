import { Main } from '@layouts/Main';
import { MainAdmin } from '@layouts/MainAdmin';
import CharactersPage from '@pages/Admin/Characters/Characters';
import NewCharacterPage from '@pages/Admin/Characters/Pages/Form';
import CodesPage from '@pages/Admin/Codes/Codes';
import NewCodePage from '@pages/Admin/Codes/Pages/Form';
import DashboardPage from '@pages/Admin/Dashboard/Dashboard';
import ItemsPage from '@pages/Admin/Items/Items';
import NewItemPage from '@pages/Admin/Items/Pages/New';
import MapsPage from '@pages/Admin/Maps/Maps';
import NewMapPage from '@pages/Admin/Maps/Pages/Form';
import NewPlayersPage from '@pages/Admin/Players/Pages/New';
import PlayersPage from '@pages/Admin/Players/Players';
import NewSettingsPage from '@pages/Admin/Settings/Pages/Form';
import SettingsPage from '@pages/Admin/Settings/Settings';
import UsersPage from '@pages/Admin/Users/Users';
import CustomisePage from '@pages/Customise/CustomisePage';
import MenuPage from '@pages/Menu/MenuPage';
import InitialPage from '@pages/Onboarding/InitialPage/InitialPage';
import ParentsPage from '@pages/Parents/ParentsPage';
import ItemFoundPage from '@pages/Play/ItemFound';
import PlayPage from '@pages/Play/Play';
import StandardPage from '@pages/Standard/StandardPage';
import ZonesPage from '@pages/Zones/Zones';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AppContainer } from './App.styled';
import './theme/app.scss';
const PageNotFound = lazy(() => import('@pages/PageNotFound'));

const App = () => {
  return (
    <AppContainer data-testid="App" id="app">
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/admin/:path?/:path?" exact>
              <MainAdmin>
                <Switch>
                  <Route path="/admin" exact component={CodesPage} />
                  <Route path="/admin/users" exact component={UsersPage} />
                  <Route
                    path="/admin/items/new"
                    exact
                    component={NewItemPage}
                  />
                  <Route
                    path="/admin/items/:id"
                    exact
                    component={NewItemPage}
                  />
                  <Route path="/admin/items" exact component={ItemsPage} />
                  <Route path="/admin/maps/new" exact component={NewMapPage} />
                  <Route path="/admin/maps/:id" exact component={NewMapPage} />
                  <Route path="/admin/maps" exact component={MapsPage} />
                  <Route
                    path="/admin/players/:id"
                    exact
                    component={NewPlayersPage}
                  />
                  <Route path="/admin/players" exact component={PlayersPage} />
                  <Route
                    path="/admin/codes/new"
                    exact
                    component={NewCodePage}
                  />
                  <Route
                    path="/admin/codes/:id"
                    exact
                    component={NewCodePage}
                  />
                  <Route path="/admin/codes" exact component={CodesPage} />
                  <Route
                    path="/admin/guardians/new"
                    exact
                    component={NewCharacterPage}
                  />
                  <Route
                    path="/admin/guardians/:id"
                    exact
                    component={NewCharacterPage}
                  />
                  <Route
                    path="/admin/guardians"
                    exact
                    component={CharactersPage}
                  />
                  <Route
                    path="/admin/settings/new"
                    exact
                    component={NewSettingsPage}
                  />
                  <Route
                    path="/admin/settings/:id"
                    exact
                    component={NewSettingsPage}
                  />
                  <Route
                    path="/admin/settings"
                    exact
                    component={SettingsPage}
                  />
                </Switch>
              </MainAdmin>
            </Route>
            <Route>
              <Main>
                <Switch>
                  <Route path="/" exact component={InitialPage} />
                  <Route path="/start/:id" exact component={InitialPage} />
                  <Route path="/menu" exact component={MenuPage} />
                  <Route path="/customise" exact component={CustomisePage} />
                  <Route path="/zones" exact component={ZonesPage} />
                  <Route path="/play/:id" exact component={ItemFoundPage} />
                  <Route path="/play" exact component={PlayPage} />
                  <Route path="/help" exact component={StandardPage} />
                  <Route path="/parents" exact component={ParentsPage} />
                  <Route path="*" component={PageNotFound} />
                </Switch>
              </Main>
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </AppContainer>
  );
};

export default App;
