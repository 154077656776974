/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import ObjectBrowser from '@components/ObjectBrowser/ObjectBrowser';
import ObjectList from '@components/ObjectList/ObjectList';
import React from 'react';

export default class MultiObjectSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showObjectSelector: false,
      obCache: {},
    };
  }

  selectOb = (ob) => {
    let { items, singular } = this.props;
    let obRef = {
      $ref: this.props.type,
      $id: ob._id,
    };

    const { fullObjects } = this.props;
    if (fullObjects) obRef = ob; //what type of browser are we using

    if (singular) items = obRef;
    if (!singular) items.push(obRef);

    this.props.updateItems(items, obRef);
  };

  removeItem = (object, index) => {
    let { items, singular } = this.props;
    if (!singular) {
      if (items[index]) items.splice(index, 1);
    } else {
      items = null;
    }
    this.props.updateItems(items);
  };

  buildItems = () => {
    let { items, singular } = this.props;
    if (singular) {
      if (items && typeof items != 'Array') items = [items];
      if (!items) items = [];
    }
    return (
      <div className={`multiObjectSelector--list-item`}>
        <ObjectList
          type={this.props.type}
          options={[
            {
              title: 'Delete',
              type: 'remove',
            },
          ]}
          references={items}
          remove={this.removeItem}
          fullObjects={this.props.fullObjects}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="multiObjectSelector">
        <button
          onClick={() => {
            this.setState({ showObjectSelector: true });
          }}
        >
          Select
        </button>
        <ObjectBrowser
          type={this.props.type}
          select={(ob) => {
            this.selectOb(ob);
          }}
          close={() => {
            this.setState({ showObjectSelector: false });
          }}
          show={this.state.showObjectSelector}
        />
        <div className="multiObjectSelector--list">{this.buildItems()}</div>
      </div>
    );
  }
}
