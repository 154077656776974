/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import client from '../../core/feathers';
import mapDataToReferences from '../../functions/mapDataToReferences';

export default class ObjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.type,
      objects: [],
      references: props.references ? [...props.references] : null,
    };
  }

  componentDidMount() {
    this.find();
  }

  componentDidUpdate(prevProps) {
    //render based on props
    if (prevProps.type != this.props.type) {
      this.setState({ type: this.props.type }, () => {
        //type changed
        this.find();
      });
      return;
    }
    if (
      (this.props.references && !this.state.references) ||
      (!this.props.references && this.state.references) ||
      (this.props.references &&
        this.state.references &&
        this.props.references.length != this.state.references.length)
    ) {
      const refs = this.props.references ? [...this.props.references] : null;
      this.setState({ references: refs }, () => {
        //type changed
        this.find();
      });
      return;
    }
  }

  find = () => {
    const { type } = this.state;
    const { fullObjects } = this.props;
    const obs = client.service(type);
    //check references props

    if (this.props.references) {
      let ids = this.props.references.map((item) => {
        if (item && item['$id']) return item['$id'];
        if (item && item['_id']) return item['_id'];
      });

      obs
        .find({
          query: {
            _id: {
              $in: ids,
            },
          },
        })
        .then((obsData) => {
          // Once both return, update the state
          const actData = mapDataToReferences(
            this.props.references,
            obsData.data,
            fullObjects
          );
          this.setState({ objects: actData });
        });
    } else {
      obs.find().then((obsData) => {
        // Once both return, update the state
        this.setState({ objects: obsData.data });
      });
    }
  };

  getObjectTitle = (object) => {
    if (object.name) return object.name;
    if (object.email) return object.email;
    if (object.originalName) return object.originalName;
    return 'undefined';
  };

  getObjectAsset = (object) => {
    if (object.asset) return object.asset;
    if (object.url) return object.url;
    return false;
  };

  getOptions = () => {
    const { options } = this.props;
    if (!options) return false;
    return options;
  };

  optionHandler = (object, index, type) => {
    this.props[type](object, index, type);
  };

  getObjectLink = (object, link) => {
    return link.replace('{id}', object._id);
  };

  deleteObject = (object) => {
    if (
      !confirm(
        `Are you sure you wish to delete ${
          (this, this.getObjectTitle(object))
        }?`
      )
    )
      return;

    const { type } = this.state;
    //we want to delete it
    client
      .service(type)
      .remove(object._id)
      .then(() => {
        //refresh de data
        this.find();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  buildContents = () => {
    const { objects } = this.state;
    if (objects.length <= 0) return [];
    const options = this.getOptions();
    return objects.map((object, index) => {
      if (!object || object == 'undefined') return;
      const asset = this.getObjectAsset(object);
      return (
        <div className="object-selectable" key={index}>
          <div className="inside">
            {asset && (
              <div className="object-asset column">
                <img src={asset} />
              </div>
            )}
            {!asset && <div className="object-asset column"></div>}
            <div className="object-name column">
              {this.getObjectTitle(object)}
            </div>
            {options && (
              <div className="object-options column">
                {options.map((option, i) => {
                  if (option.type == 'link')
                    return (
                      <Link
                        key={`opt-${index}-${i}`}
                        to={this.getObjectLink(object, option.link)}
                        className="button"
                      >
                        {option.title}
                      </Link>
                    );
                  if (option.type == 'delete')
                    return (
                      <button
                        key={`opt-${index}-${i}`}
                        onClick={() => {
                          this.deleteObject(object);
                        }}
                        className="purple"
                      >
                        {option.title}
                      </button>
                    );

                  return (
                    <button
                      key={`opt-${index}-${i}`}
                      onClick={() => {
                        this.optionHandler(object, index, option.type);
                      }}
                    >
                      {option.title}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  selectObject = (object) => {
    this.props.select(object);
    this.props.close();
  };

  render() {
    return (
      <div className={`objectList`}>
        <div className="ob-content">
          <div className="ob-content--content">{this.buildContents()}</div>
        </div>
      </div>
    );
  }
}
