/* eslint-disable prettier/prettier */
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

export default class HelpCarousel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Carousel
        showIndicators={true}
        showThumbs={false}
        showStatus={false}
        showArrows={true}
        autoPlay={false}
        interval={50000000}
      >
        <div>
          <img src="/images/about-graphic-1@2x.svg" />

          <div className="text">
            Discover the Guardians of Wentworth Woodhouse and see what they’re
            hiding in this free game
          </div>
        </div>
        <div>
          <img src="/images/about-graphic-2@2x.png" />

          <div className="text">
            You are an Adventurer! Find and scan the QR codes hidden throughout
            the grounds to find the Guardians
          </div>
        </div>
        <div>
          <img src="/images/about-graphic-3@2x.png" />

          <div className="text">
            When you meet a Guardian, they will tell you about themselves, and
            give you something
          </div>
        </div>
        <div>
          <img src="/images/about-graphic-4@2x.png" />

          <div className="text">
            You can then leave something, and become part of Wentworth Woodhouse
            history!
          </div>
        </div>
        <div>
          <img src="/images/about-graphic-5@2x.png" />

          <div className="text">
            Finding Guardians also unlocks special outfits to style your
            Adventurer!
          </div>
        </div>
      </Carousel>
    );
  }
}
