/* eslint-disable prettier/prettier */
import Logo from '@components/Logo/Logo';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Link } from 'react-router-dom';
import switchBackground from '@functions/switchBackground';
export default class ParentsPage extends React.Component {
  constructor(props) {
    super(props);
  }
  componentWillUnmount() {
    switchBackground('none');
  }
  componentDidMount() {
    switchBackground('lightOrange');
  }
  render() {
    return (
      <div className="page standardPage background lightOrange with-image">
        <div className="button-container left" style={{ zIndex: 13 }}>
          <Link className="button green no-text" to="/menu">
            <span className="back" />
          </Link>
          <Link className="button green no-text right" to="/help">
            <span className="info-white" />
          </Link>
        </div>

        <div className="whitePageInside no-margin">
          <h2>Information for adults</h2>
          <Carousel
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
            showArrows={true}
            autoPlay={false}
            interval={50000000}
          >
            <div>
              <img src="/images/adults-graphic-1@2x.svg" />

              <div className="text">
                Welcome to Hide n&#39; Find: A game for 7+ to explore the
                grounds of Wentworth Woodhouse! It takes about 15 minutes to
                play a game.
              </div>
            </div>
            <div>
              <img src="/images/adults-graphic-2@2x.png" />

              <div className="text">
                There are 3 play zones around the gardens of the house, and each
                zone is a complete game.
              </div>
            </div>
            <div>
              <img src="/images/adults-graphic-3@2x.png" />

              <div className="text">
                Children must find and scan the QR codes to meet the Guardians
                of Wentworth, hear their story and swap items with them.
                <br />
                <br />
                They can even customise their own character.
              </div>
            </div>
            <div>
              <img src="/images/adults-graphic-4@2x.png" />

              <div className="text">
                This site uses cookies to save your progress in the game, and
                requires access to the camera in order to scan the QR codes, we
                don’t store any unique or personal information throughout the
                experience.
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    );
  }
}
