/* eslint-disable prettier/prettier */
import client from '../core/feathers';
import {
  uniqueNamesGenerator,
  animals,
  NumberDictionary,
} from 'unique-names-generator';
import getMapFromMarker from '@functions/getMapFromMarker';

export function isPlayer() {
  let player = localStorage.getItem('player');
  if (!player) return false;
  player = JSON.parse(player);
  if (player && !player._id) return false;
  return player;
}

export function setPlayer(player) {
  localStorage.setItem('player', JSON.stringify(player));
  return true;
}

export function getPlayerName() {
  const numberDictionary = NumberDictionary.generate({ min: 100, max: 999 });
  const customConfig = {
    dictionaries: [animals, numberDictionary],
    separator: '-',
    length: 2,
  };

  return uniqueNamesGenerator(customConfig); // big-donkey
}

export function loadPlayer(f) {
  const _player = isPlayer();
  if (!_player) return false;

  client
    .service('players')
    .get(_player._id)
    .then((player) => {
      setPlayer(player);
      if (f) f(player);
    })
    .catch((error) => {
      console.log(error, 'player error');
      if (f) f(false);
    });
}

export function savePlayer(p, f) {
  client
    .service('players')
    .update(p._id, p)
    .then((player) => {
      setPlayer(player);
      f(player);
    })
    .catch((error) => {
      console.log(error, 'error saving player');
    });
  return;
}

export function setAvatar(p, a) {
  p.avatar = a;
  return p;
}

export function unlockAreaFromMarker(p, m, f) {
  client
    .service('maps')
    .find()
    .then((maps) => {
      //check for the marker
      let map = getMapFromMarker(maps.data, m);
      if (map) {
        p = unlockArea(p, map);
      }
      f(p);
    })
    .catch((error) => {
      f(p);
    });
}

export function unlockArea(p, a) {
  if (!p.unlocked) {
    p.unlocked = [a._id];
    return p;
  }
  let alreadyUnlocked = false;
  for (let i = 0; i < p.unlocked.length; i++) {
    if (p.unlocked[i] == a._id) {
      alreadyUnlocked = true;
    }
  }

  if (!alreadyUnlocked) p.unlocked.push(a._id);

  return p;
}

export function getPlayerAvatar(player) {
  if (!player.avatar) {
    return {
      characterObj: {
        assetAvatarSmall: '/images/avatarSmall.png',
        assetAvatarLarge: '/images/avatarLarge.png',
        assetAvatarScene: '/images/avatarScene.png',
        name: 'Milton (default)',
      },
    };
  }

  return player.avatar;
}

export function removeItem(player, item) {
  player.tradeable.forEach((_item, i) => {
    if (_item._id == item._id) player.tradeable.splice(i, 1);
  });

  return player;
}

export function addItem(player, item) {
  player.tradeable.push(item);
  return player;
}

export function addTrade(player, marker, oldItem, newItem) {
  createTradeObject(player, marker, oldItem, newItem);
  player.trades.push({
    marker: marker._id,
    oldItem: oldItem._id,
    newItem: newItem._id,
    time: Date.now(),
  });
  return player;
}

export function addGuardian(player, marker) {
  player.found.push(marker._id);
  return player;
}

function createTradeObject(player, marker, oldItem, newItem) {
  const trade = {
    player: player,
    playerId: player._id,
    markerId: marker._id,
    marker: marker,
    oldItem: oldItem,
    newItem: newItem,
    time: Date.now(),
  };
  client
    .service('trades')
    .create(trade)
    .then((trade) => {
      console.log('trade created', trade);
    })
    .catch((error) => {
      console.log('trade error', error);
    });
}
