/* eslint-disable prettier/prettier */
import React from 'react';
import './Orientation.scss';

export default class Orientation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompat: false,
    };
  }
  componentDidMount() {
    const hasContinued = localStorage.getItem('compatContinue');
    if (hasContinued) {
      this.setState({ showCompat: false });
    } else {
      this.setState({ showCompat: true });
    }
  }
  render() {
    const { showCompat } = this.state;
    return (
      <>
        <div className="orientation background">
          <div>Please turn your screen to portrait</div>
        </div>
        {showCompat && (
          <div className="compatibility background">
            <div>
              <p>
                This experience is designed for mobile devices, and uses the
                camera.
              </p>
              <button
                className="button"
                onClick={() => {
                  this.setState({ showCompat: false }, () => {
                    localStorage.setItem('compatContinue', true);
                  });
                }}
              >
                Continue Anyway
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}
