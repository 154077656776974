/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import './GuardianFoundPopup.scss';
import { isPlayer, loadPlayer } from '../../functions/players';
import client from '../../core/feathers';
export default class GuardianFoundPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      m: false,
      loading: true,
    };
  }

  componentDidMount() {
    const s = localStorage.getItem('pop.guardian.shown');
    if (s == 'yes') return;

    const m = localStorage.getItem('pop.guardian.found');
    if (m) {
      this.setState({ m: JSON.parse(m), show: false }, () => {
        localStorage.setItem('pop.guardian.shown', 'yes');
        this.getPlayer(() => {
          this.getMaps();
        });
      });
    }
  }

  getMaps = () => {
    client
      .service('maps')
      .find()
      .then((maps) => {
        this.setState({ maps: maps.data }, this.getFoundNum);
      })
      .catch((error) => {
        console.log('no maps?', error);
      });
  };

  getPlayer = (f) => {
    const player = isPlayer();
    if (!player) return console.log('not logged in (no player found)');
    loadPlayer((p) => {
      if (!p) return console.log('error fetching player');
      this.setState({ player: p }, () => {
        f();
      });
    });
  };

  getFoundNum = () => {
    const { maps, player, m } = this.state;
    console.log(maps, player, m);
    let map = false;
    for (let i = 0; i < maps.length; i++) {
      for (let c = 0; c < maps[i].codes.length; c++) {
        if (maps[i].codes[c]._id == m._id) {
          //this is the map
          map = maps[i];
        }
      }
    }

    if (!map) return;
    const count = this.getCount(map);
    this.setState({ foundNum: count, loading: false, show: true, map });
  };

  checkStage = (marker) => {
    const { player } = this.state;
    if (player.trades && player.trades.length > 0) {
      for (let i = 0; i < player.trades.length; i++) {
        if (player.trades[i].marker == marker._id) {
          return true;
        }
      }
    }

    return false;
  };

  getCount = (map) => {
    let num = 0;
    for (let i = 0; i < map.codes.length; i++) {
      if (this.checkStage(map.codes[i])) num++;
    }
    return num;
  };

  render() {
    const { m, show, loading, foundNum, map } = this.state;
    if (!m || loading) {
      return <></>;
    }

    const showClass = show ? 'show' : 'hide';
    const char = m.character;

    return (
      <div className={`popup guardianFound fadeInUp ${showClass}`}>
        <div className="inside">
          <button
            className="close-btn"
            onClick={() => {
              this.setState({ show: false });
            }}
          />
          <div className="heading">
            <div className="font-20 krana lh-20 fadeInUp delay-1">
              Well Done
            </div>
            <div className="font-40 krana lh-40 fadeInUp delay-2">
              Adventurer!
            </div>
          </div>
          <div className="text fadeInUp delay-3">
            You have unlocked a new
            <br />
            <span className="orange bold">Guardians' Outfit!</span>
          </div>
          <div className="text last fadeInUp delay-4">
            You have found {foundNum} of {map.codes.length} guardians in{' '}
            {map.name}
          </div>
          <div className="anim-burst-lines fadeInUp delay-6" />
          <div className="char-pp fadeInUp delay-5">
            <img src={char.assetAvatarSmall} />
          </div>
        </div>
      </div>
    );
  }
}
