import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

const global = css`
  body {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body *,
  body *::before,
  body *::after {
    border-collapse: collapse;
    box-sizing: border-box;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${global}
`;
