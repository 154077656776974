/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import MultiObjectSelector from '@components/MultiObjectSelector/MultiObjectSelector';
import MultiTextInput from '@components/MultiTextInput/MultiTextInput';
import MediaBrowser from '@pages/Admin/Media/components/MediaBrowser/MediaBrowser';
import React from 'react';
import { Redirect } from 'react-router';
import client from '../../../../core/feathers';
import { DefaultEditor } from 'react-simple-wysiwyg';

export default class NewCharacterPage extends React.Component {
  constructor(props) {
    super(props);
    const edit =
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : false;
    this.state = {
      showBrowser: false,
      redirect: false,
      edit,
      formValues: {
        name: '',
        asset: false,
        items: [],
        script: [],
        preTradeScript: [],
        infoText: '',
        title: '',
        tradeListText:
          'Look at all the gifts I’ve been given! Can you spot yours?',
        published: false,
      },
      notFound: false,
      loading: edit ? true : false,
      saved: false,
    };
  }

  componentDidMount() {
    const { edit } = this.state;
    if (edit) this.loadObject();
  }

  loadObject = () => {
    const { edit } = this.state;
    const items = client.service('characters');
    Promise.all([items.get(edit)])
      .then(([itemData]) => {
        const item = itemData;
        // Once both return, update the state
        this.setState({ formValues: item, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false, notFound: true });
      });
  };

  setValue = (type, value) => {
    let { formValues } = this.state;
    formValues[type] = value;
    this.setState({ formValues });
  };

  showMediaBrowser = (type) => {
    this.setState({ showBrowser: true, mediaBrowserType: type });
  };

  hideMediaBrowser = () => {
    this.setState({ showBrowser: false });
  };

  checkValues = (vals) => {
    if (!vals.asset || !vals.name || vals.name == '') return false;

    return true;
  };

  save = () => {
    const { edit } = this.state;
    if (edit) return this.update();

    return this.create();
  };

  update = () => {
    const { formValues } = this.state;
    if (!this.checkValues(formValues)) return false;
    return client
      .service('characters')
      .update(formValues._id, formValues)
      .then(() => {
        //reset
        this.setState({
          saved: true,
        });
      });
  };

  create = () => {
    const { formValues } = this.state;
    if (!this.checkValues(formValues)) return false;
    return client
      .service('characters')
      .create(formValues)
      .then(() => {
        //reset
        this.setState({
          redirect: true,
        });
      });
  };

  updateItems = (items, refOb) => {
    const { formValues } = this.state;
    formValues.items = items;
    this.setState({ formValues });
  };

  render() {
    const {
      formValues,
      showBrowser,
      redirect,
      loading,
      notFound,
      edit,
      saved,
    } = this.state;
    if (redirect) {
      return <Redirect to="/admin/guardians" />;
    }
    if (notFound) {
      return <div className="notFound">404 not found</div>;
    }
    if (loading) {
      return <div className="loading">Loading...</div>;
    }

    const scriptItems =
      formValues.script && Array.isArray(formValues.script)
        ? formValues.script
        : [];

    const preTradeItems =
      formValues.preTradeScript && Array.isArray(formValues.preTradeScript)
        ? formValues.preTradeScript
        : [];

    return (
      <div>
        {edit && <h1>Edit {formValues.name}</h1>}
        {!edit && <h1>Add New Guardian</h1>}
        {saved && <div className="formNote positive">Guardian saved</div>}
        <div className="form">
          <div className="formItem">
            <label htmlFor="name">Guardian Name</label>
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={(e) => {
                this.setValue('name', e.target.value);
              }}
            />
          </div>
          <div className="formItem">
            <label htmlFor="name">Guardian Title</label>
            <input
              type="text"
              name="title"
              value={formValues.title}
              onChange={(e) => {
                this.setValue('title', e.target.value);
              }}
            />
          </div>
          <div className="formItem">
            <label htmlFor="name">Selected Asset</label>
            <div className="formItem--asset">
              <div>{formValues.asset && <img src={formValues.asset} />}</div>
              <div>
                <button
                  onClick={() => {
                    this.showMediaBrowser('asset');
                  }}
                >
                  change
                </button>
              </div>
            </div>
          </div>
          <div className="formItem">
            <label htmlFor="name">Avatar (small)</label>
            <div className="formItem--asset">
              <div>
                {formValues.assetAvatarSmall && (
                  <img src={formValues.assetAvatarSmall} />
                )}
              </div>
              <div>
                <button
                  onClick={() => {
                    this.showMediaBrowser('assetAvatarSmall');
                  }}
                >
                  change
                </button>
              </div>
            </div>
          </div>
          <div className="formItem">
            <label htmlFor="name">Avatar (Large)</label>
            <div className="formItem--asset">
              <div>
                {formValues.assetAvatarLarge && (
                  <img src={formValues.assetAvatarLarge} />
                )}
              </div>
              <div>
                <button
                  onClick={() => {
                    this.showMediaBrowser('assetAvatarLarge');
                  }}
                >
                  change
                </button>
              </div>
            </div>
          </div>
          <div className="formItem">
            <label htmlFor="name">Avatar (Scene)</label>
            <div className="formItem--asset">
              <div>
                {formValues.assetAvatarScene && (
                  <img src={formValues.assetAvatarScene} />
                )}
              </div>
              <div>
                <button
                  onClick={() => {
                    this.showMediaBrowser('assetAvatarScene');
                  }}
                >
                  change
                </button>
              </div>
            </div>
          </div>
          <MediaBrowser
            show={showBrowser}
            hide={this.hideMediaBrowser}
            select={(img) => {
              const { mediaBrowserType } = this.state;
              this.setValue(mediaBrowserType, img.url);
            }}
          />
          <div className="formItem">
            Script:
            <MultiTextInput
              items={scriptItems}
              hasCheckbox={true}
              checkboxTitle="User"
              onChange={(items) => {
                this.setValue('script', items);
              }}
            />
          </div>
          <div className="formItem">
            Pre-trade text:
            <MultiTextInput
              items={preTradeItems}
              hasCheckbox={true}
              checkboxTitle="User"
              onChange={(items) => {
                this.setValue('preTradeScript', items);
              }}
            />
          </div>
          <div className="formItem">
            <label htmlFor="name">Post Trade Text</label>
            <input
              type="text"
              name="name"
              value={formValues.tradeListText}
              onChange={(e) => {
                this.setValue('tradeListText', e.target.value);
              }}
            />
          </div>
          <div className="formItem">
            <label>
              Published?
              <input
                name="published"
                type="checkbox"
                checked={formValues.published}
                onChange={(e) => {
                  this.setValue('published', e.target.checked);
                }}
              />
            </label>
          </div>
          <div className="formItem">
            <label htmlFor="name">Available items pool</label>
            <MultiObjectSelector
              type="items"
              items={formValues.items}
              updateItems={this.updateItems}
            />
          </div>
        </div>
        <div className="formItem">
          <label htmlFor="name">Help Text (avatar page)</label>
          <DefaultEditor
            type="text"
            name="infoText"
            value={formValues.infoText}
            onChange={(e) => {
              this.setValue('infoText', e.target.value);
            }}
          />
        </div>
        <div className="formItem formSubmit">
          <button onClick={this.save}>Save</button>
        </div>
      </div>
    );
  }
}
