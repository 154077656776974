/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import PopulateMarker from '../../functions/populateMarker';
import client from '../../core/feathers';
import FoundStage1 from './FoundStages/FoundStage1';
import FoundStage2 from './FoundStages/FoundStage2';
import FoundStage3 from './FoundStages/FoundStage3';
import {
  addItem,
  isPlayer,
  loadPlayer,
  removeItem,
  addTrade,
  addGuardian,
  savePlayer,
  unlockArea,
  unlockAreaFromMarker,
} from '../../functions/players';
import GuardianPage from './FoundStages/GuardianPage';
import Logo from '@components/Logo/Logo';
import Character from '@components/Character/Character';

import './ItemFound.scss';
import FoundStage4 from './FoundStages/FoundStage4';
import { Redirect } from 'react-router';
import switchBackground from '@functions/switchBackground';

export default class ItemFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markerID: props.match.params.id,
      loading: true,
      marker: false,
      stage: 'initial',
      redirect: false,
    };
  }

  componentDidMount() {
    switchBackground('blue');
    const root = document.getElementsByTagName('html')[0];
    root.classList.add('blue');
    document.body.classList.add('blue');
    this.getPlayer(() => {
      this.getMarker();
    });
  }

  componentWillUnmount() {
    switchBackground('none');
    const root = document.getElementsByTagName('html')[0];
    root.classList.remove('blue');
    document.body.classList.remove('blue');
  }

  onboard = () => {
    localStorage.setItem('onboard-play', this.state.markerID);
    this.setState({ redirect: `/` });
  };

  getPlayer = (f) => {
    const player = isPlayer();
    if (!player) {
      this.onboard();
      return console.log('not logged in (no player found)');
    }
    loadPlayer((p) => {
      if (!p) return console.log('error fetching player');
      this.setState({ player: p }, () => {
        f();
      });
    });
  };

  getMarker = () => {
    const codes = client.service('codes');
    codes
      .get(this.state.markerID)
      .then((result) => {
        this.setState({ marker: result }, () => {
          PopulateMarker(this.state.marker, (fullMarker) => {
            this.setState({ marker: fullMarker }, () => {
              this.checkStage();
            });
          });
        });
      })
      .catch((error) => {
        //marker not found
        console.log('marker does not exist', error);
      });
  };

  checkStage = () => {
    const { player, marker } = this.state;
    unlockAreaFromMarker(player, marker, (p) => {
      //update the user
      savePlayer(p, (savedPlayer) => {
        this.setState({ player: savedPlayer });
      });
    });
    if (player.trades) {
      player.trades.forEach((trade) => {
        if (trade.marker == marker._id) {
          this.setState({ loading: false, stage: 'done' });
          return;
        }
      });
    }

    this.setState({ loading: false });
  };

  step = () => {
    //something
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const { stage } = this.state;
    if (stage == 'initial') return this.setState({ stage: 'dialog' });
    if (stage == 'dialog') return this.setState({ stage: 'trade' });
    if (stage == 'trade-dialog') return this.setState({ stage: 'trade' });
    if (stage == 'trade')
      return this.setState({ stage: 'done', loading: false });
  };

  trade = (oldItem, index) => {
    //trade ze item!
    let { player, marker } = this.state;
    let newItem = marker.character.items[0];
    player = removeItem(player, oldItem);
    player = addItem(player, newItem);
    player = addTrade(player, marker, oldItem, newItem);
    player = addGuardian(player, marker);

    //save the player
    this.setState({ loading: true }, () => {
      savePlayer(player, (p) => {
        //do some fresh tings
        localStorage.setItem('pop.guardian.found', JSON.stringify(marker));
        this.step();
      });
    });
    return;
  };

  render() {
    const { loading, redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    if (loading) return <div className="loading">loading</div>;

    const { marker, stage, player } = this.state;
    const visible = stage == 'initial' || stage == 'trade' ? false : true;
    const charSize = stage == 'done' ? 'small' : 'large';
    const guardianSticky = stage == 'done' ? false : true;
    const guardianClass = stage == 'done' ? 'smaller' : 'larger';
    return (
      <div className={`itemFound-page page ${stage} blue background`}>
        <Logo sticky={false} />
        {stage == 'initial' && <FoundStage1 marker={marker} step={this.step} />}
        {stage == 'dialog' && <FoundStage2 marker={marker} step={this.step} />}
        {stage == 'trade-dialog' && (
          <FoundStage3 marker={marker} step={this.step} />
        )}
        {stage == 'trade' && (
          <FoundStage4
            marker={marker}
            step={this.step}
            player={player}
            trade={this.trade}
          />
        )}

        {stage == 'done' && (
          <GuardianPage marker={marker} step={this.step} player={player} />
        )}
        <Character
          marker={marker}
          visible={visible}
          size={charSize}
          isSticky={guardianSticky}
          className={guardianClass}
        />
      </div>
    );
  }
}
