/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import Asset from '@components/Asset/Asset';
import React from 'react';

export default class FoundStage2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };

    this.script = [
      'Some text script 1',
      'some more text from script 2',
      'a third line of text to seal the scripting deal',
    ];
  }

  getStyle = () => {
    const padding = document.getElementById('character').offsetHeight - 30;
    return {
      paddingBottom: `${padding}px`,
    };
  };

  next = () => {
    const { index } = this.state;
    const script = this.getScript();
    if (index + 1 > script.length - 1) return this.props.step();

    let autoNext = false;
    if (script[index + 2] && !script[index + 2].isUser) {
      autoNext = true;
    }

    this.setState({ index: index + 1 }, () => {
      setTimeout(() => {
        document.getElementsByTagName('body')[0].scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
        if (autoNext) {
          setTimeout(() => {
            this.next();
          }, 1500);
        }
      }, 500);
    });
  };

  getText = () => {
    const { index } = this.state;
    const { marker } = this.props;
    const script = this.getScript();
    let pos = 0;
    return script.map((item, n) => {
      pos += 1;
      if (pos > 3) {
        pos = 0;
      }
      let txt = item;
      let isUser = false;
      if (typeof item != 'string') {
        txt = item.text;
        if (item.isUser) isUser = true;
      }

      const isUserClass = isUser ? 'user' : 'character-bubble';
      let arrowClass = '';

      if (!isUser && index >= n + 1 && !script[n + 1].isUser) {
        //don't show the arrow
        arrowClass = 'noArrow';
      }

      txt = txt.replace(
        '{item}',
        "<img src='" + marker.character.items[0].asset + "' />"
      );

      txt = txt.replace(
        '{name}',
        "<span class='colour-orange bold'>" + marker.character.name + '</span>'
      );

      if (n <= index) {
        return (
          <div
            className={`speech n-${pos} ${isUserClass} ${arrowClass}`}
            key={n}
          >
            <div className="inside" dangerouslySetInnerHTML={{ __html: txt }} />
          </div>
        );
      }
    });
  };

  getContinueButton = () => {
    const { index } = this.state;
    const script = this.getScript();

    let button = {
      text: 'Continue',
      color: 'orange',
      show: true,
      font: 'krana',
    };

    if (!script[index + 1]) return button;

    let item = script[index + 1];

    if (item.isUser) {
      button.text = item.text;
      button.color = 'limeGreen';
      button.font = 'dm-sans';
    } else {
      button.show = false;
    }

    return button;
  };

  getScript = () => {
    const { marker } = this.props;
    if (!marker.character.script) return this.script;
    if (marker.character.script && marker.character.script.length <= 0)
      return this.script;

    return marker.character.script;
  };

  render() {
    const button = this.getContinueButton();
    const showSend = button.color == 'limeGreen' ? true : false;
    return (
      <>
        <div className="speech-container" style={this.getStyle()}>
          {this.getText()}
          <div className="button-container bottom z-101">
            {button.show && button.color == 'limeGreen' && (
              <button
                className={`button fadeInUp delay-10 flex justify-center align-center margin-auto font-25 ${button.color} ${button.font}`}
                onClick={this.next}
              >
                <span className="text">{button.text}</span>
                {showSend && <span className="sendIcon" />}
              </button>
            )}
            {button.show && button.color == 'orange' && (
              <button
                className={`button fadeInUp delay-10 ${button.color} ${button.font}`}
                onClick={this.next}
              >
                <span className="text">{button.text}</span>
                {showSend && <span className="sendIcon" />}
              </button>
            )}
            {!button.show && (
              <div className="typing fadeInUp delay-1">
                <div className="typingBubble typingAnim1" />
                <div className="typingBubble typingAnim2" />
                <div className="typingBubble typingAnim3" />
              </div>
            )}
          </div>
        </div>
        <div className="blue-fader" />
      </>
    );
  }
}
