/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import { ReactMediaLibrary } from 'react-media-library';
import client from '../../../../../core/feathers';
import './MediaBrowser.scss';

class MediaBrowser extends Component {
  constructor(props: any) {
    super(props);

    this.state = {
      fileLibraryList: [],
      type: props.type ? props.type : 'image',
    };
  }

  componentDidMount() {
    this.loadFileLibrary();
  }

  loadFileLibrary = () => {
    const media = client.service('media');
    media.find({ query: { type: this.state.type } }).then((medias) => {
      this.setState({ fileLibraryList: this.parseMediaData(medias.data) });
    });
  };

  parseMediaData = (medias) => {
    return medias.map((item, i) => {
      item.createdAt = new Date(item.createdAt);
      item.actualFileName = item.fileName;
      item.fileName = item.originalName;
      return item;
    });
  };

  handleUploadResponse = (response, FileMeta) => {
    return {
      fileName: response.id,
      size: response.size,
      type: this.state.type,
      originalName: FileMeta.fileName,
    };
  };

  uploadCallback = (base64, FileMeta) => {
    const upload = client.service('upload');
    upload.timeout = 60000;
    const media = client.service('media');
    const { type } = this.state;

    return upload.create({ uri: base64, type }).then((response) => {
      // success
      return media
        .create(this.handleUploadResponse(response, FileMeta))
        .then((item) => {
          this.loadFileLibrary();
          return true;
        });
    });
  };

  deleteCallback = (item) => {
    const media = client.service('media');
    media.remove(item._id).then(() => {
      this.loadFileLibrary();
    });
    return true;
  };

  selectCallback = (item) => {
    const { hide, select } = this.props;
    item.fileName = item.actualFileName;
    hide();
    select(item);
  };

  render() {
    const { show, hide } = this.props;
    const { fileLibraryList } = this.state;
    const { uploadCallback, selectCallback, deleteCallback } = this;
    return (
      <ReactMediaLibrary
        show={show}
        onHide={() => hide()}
        fileUploadCallback={uploadCallback}
        fileLibraryList={fileLibraryList}
        fileSelectCallback={selectCallback}
        fileDeleteCallback={deleteCallback}
      />
    );
  }
}

export default MediaBrowser;
