/* eslint-disable prettier/prettier */
import Asset from '@components/Asset/Asset';
import Loading from '@components/Loading/Loading';
import React from 'react';
import { Link } from 'react-router-dom';
import { isPlayer, loadPlayer } from '../../functions/players';
import client from '../../core/feathers';
import Logo from '@components/Logo/Logo';
import './Zones.scss';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import switchBackground from '@functions/switchBackground';

export default class ZonesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      maps: [],
      showCarousel: false,
      selectedItem: 0,
    };
  }

  componentWillUnmount() {
    switchBackground('none');
  }

  componentDidMount() {
    switchBackground('lightOrange');
    this.getPlayer(this.getMaps);
  }

  checkStage = (marker) => {
    const { player } = this.state;
    if (player.trades && player.trades.length > 0) {
      for (let i = 0; i < player.trades.length; i++) {
        if (player.trades[i].marker == marker._id) {
          return true;
        }
      }
    }

    return false;
  };

  getMaps = () => {
    client
      .service('maps')
      .find()
      .then((maps) => {
        let selectedItem = 0;
        const startmap = localStorage.getItem('_startMap');
        if (startmap) {
          for (let i = 0; i < maps.data.length; i++) {
            if (maps.data[i]._id == startmap) selectedItem = i;
          }
        }
        this.setState(
          { maps: maps.data, showCarousel: true, selectedItem },
          this.getItems
        );
      })
      .catch((error) => {
        console.log('no maps?', error);
      });
  };

  getPlayer = (f) => {
    const player = isPlayer();
    if (!player) return console.log('not logged in (no player found)');
    loadPlayer((p) => {
      if (!p) return console.log('error fetching player');
      this.setState({ player: p, loading: false }, () => {
        f();
      });
    });
  };

  getCount = (map) => {
    const { player } = this.state;
    let num = 0;
    for (let i = 0; i < map.codes.length; i++) {
      if (this.checkStage(map.codes[i])) num++;
    }
    return num;
  };

  buildZones = () => {
    const { maps } = this.state;
    return maps.map((map, i) => {
      return (
        <div className={`zone font-20`} key={i}>
          <div className="zone--title bold">{map.name}</div>
          <div className="zone--count">
            {this.getCount(map)} of {map.codes.length} Guardians found
          </div>
          <div className="zone--image">
            <img src={map.mapAsset} />
          </div>
          <div
            className="zone--info text"
            dangerouslySetInnerHTML={{ __html: map.infoText }}
          />
        </div>
      );
    });
  };

  render() {
    const { loading, showCarousel, selectedItem } = this.state;
    if (loading) return <Loading />;

    return (
      <div className="page page-zones background lightOrange no-padding">
        <Logo colour="purple" />
        <div className="button-container left" style={{ zIndex: 13 }}>
          <Link className="button green no-text" to="/menu">
            <span className="back" />
          </Link>
          <Link className="button green no-text right" to="/help">
            <span className="info-white" />
          </Link>
        </div>
        <div className="whitePageInside no-margin">
          <div className="zones-carousel">
            {showCarousel && (
              <Carousel
                showIndicators={true}
                showThumbs={false}
                showStatus={false}
                showArrows={true}
                autoPlay={false}
                interval={50000000}
                selectedItem={selectedItem}
              >
                {this.buildZones()}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    );
  }
}
