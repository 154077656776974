/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';

export default class MultiTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //fix for legacy  items
  fixItem = (item) => {
    if (typeof item == 'string') {
      item = { text: item, isUser: false };
    }

    return item;
  };

  onChange = (value, index) => {
    let { items } = this.props;
    items[index] = this.fixItem(items[index]);
    items[index].text = value;
    this.props.onChange(items);
  };

  onChangeChexbox = (value, index) => {
    let { items } = this.props;
    items[index] = this.fixItem(items[index]);
    items[index].isUser = value;
    this.props.onChange(items);
  };

  removeItem = (index) => {
    let { items } = this.props;
    if (!items[index]) return;

    items.splice(index, 1);
    this.props.onChange(items);
  };

  addItem = () => {
    let { items } = this.props;
    items.push({ text: `Script item ${items.length + 1}`, isUser: false });
    this.props.onChange(items);
  };

  buildItems = () => {
    const { items, hasCheckbox, checkboxTitle } = this.props;
    return items.map((item, index) => {
      return (
        <div className="multiTextInput--item" key={index}>
          <input
            type="text"
            value={item.text}
            onChange={(e) => {
              this.onChange(e.target.value, index);
            }}
          />
          {hasCheckbox && (
            <>
              <input
                type="checkbox"
                id={`i-${index}`}
                checked={item.isUser}
                onChange={(e) => {
                  this.onChangeChexbox(e.target.checked, index);
                }}
              />{' '}
              <label htmlFor={`i-${index}`}>{checkboxTitle}</label>
            </>
          )}
          <button
            onClick={() => {
              this.removeItem(index);
            }}
          >
            delete
          </button>
        </div>
      );
    });
  };

  render() {
    return (
      <div className="multiTextInput">
        {this.buildItems()}
        <div>
          <button onClick={this.addItem}>+ new script item</button>
        </div>
      </div>
    );
  }
}
