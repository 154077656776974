/* eslint-disable prettier/prettier */
export function setSettings(json) {
  //render and set the settings
  if (!json.data) return false;
  let data = {};
  for (let i = 0; i < json.data.length; i++) {
    let item = json.data[i];
    data[item.name] = item;
  }
  localStorage.setItem('__settings', data);
}

export function getSetting(setting, def) {
  const settings = localStorage.getItem('__settings');
  if (!settings[setting]) return def;

  return settings[setting];
}
