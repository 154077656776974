/* eslint-disable prettier/prettier */
import Logo from '@components/Logo/Logo';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Link } from 'react-router-dom';
import switchBackground from '@functions/switchBackground';
import HelpCarousel from './HelpCarousel';
export default class StandardPage extends React.Component {
  constructor(props) {
    super(props);
  }
  componentWillUnmount() {
    switchBackground('none');
  }
  componentDidMount() {
    switchBackground('lightBlue');
  }
  render() {
    return (
      <div className="page standardPage background lightBlue with-image">
        <div className="button-container left" style={{ zIndex: 13 }}>
          <Link className="button purple no-text" to="/menu">
            <span className="back" />
          </Link>
        </div>

        <div className="whitePageInside no-margin">
          <h2>About Hide & Find</h2>
          <HelpCarousel />
        </div>
      </div>
    );
  }
}
