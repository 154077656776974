/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import Orientation from '@components/Orientation/Orientation';
import FoundTransition from '@components/FoundTransition/FoundTransition';
import LoadAssets from '@pages/LoadAssets/LoadAssets';
import React from 'react';
import { MainContainer } from './Main.styled';

// export const Main = ({ children }: Props) => {
//   if(!window.loadedAssets) return <LoadAssets onComplete={} />
//   return (
//     <MainContainer data-testid="Main" id="main">
//       <Orientation />
//       {children}
//     </MainContainer>
//   );
// };

export class Main extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      background: 'none',
    };
  }
  loaded = () => {
    this.setState({ loaded: true });
  };
  componentDidMount() {
    window.addEventListener('switchBackground', this.switchBackground);
  }
  switchBackground = (e) => {
    const { background } = this.state;
    document.body.classList.remove(`body-background-${background}`);
    document.body.classList.add(`body-background-${e.detail}`);

    const root = document.getElementsByTagName('html')[0];
    root.classList.remove(`body-background-${background}`);
    root.classList.add(`body-background-${e.detail}`);
    this.setState({ background: e.detail });
  };
  render() {
    const { loaded, background } = this.state;
    const { children } = this.props;
    return (
      <>
        <LoadAssets onComplete={this.loaded} />
        <MainContainer
          data-testid="Main"
          id="main"
          className={`main-background-${background}`}
        >
          <FoundTransition />
          <Orientation />
          {children}
        </MainContainer>
      </>
    );
  }
}
