/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import Asset from '@components/Asset/Asset';
import AvatarSmall from '@components/Avatar/AvatarSmall';
import React from 'react';
import { Link } from 'react-router-dom';
import client from '../../../core/feathers';
import ScrollContainer from 'react-indiana-drag-scroll';
import TimeAgo from 'react-timeago';
export default class GuardianPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trades: [],
      scrollStarted: false,
    };
  }

  componentDidMount() {
    this.getTrades();
    this.setEventListeners();
    localStorage.setItem('showScannerBtn', 'yes');
    setTimeout(() => {
      this.onScrollStart();
    }, 5000);
  }

  setEventListeners = () => {
    client.service('trades').on('created', this.onNewTrade);
  };

  removeEventListeners = () => {
    client.service('trades').removeListener('created');
  };

  onNewTrade = (data) => {
    const { trades } = this.state;
    trades.push(data);
    this.setState({ trades });
  };

  getTrades = () => {
    const { marker } = this.props;

    client
      .service('trades')
      .find({
        query: { markerId: marker._id, $limit: 10, $sort: { time: -1 } },
      })
      .then((trades) => {
        this.setState({ trades: trades.data }, () => {
          setTimeout(() => {
            const el = document.getElementsByClassName(
              'tradesList-scroller'
            )[0];
            const cont = document.getElementsByClassName('trade-container')[0];
            let pos = this.getIndicatorPos();
            let scroll =
              cont.offsetWidth * pos -
              (cont.offsetWidth + cont.offsetWidth / 3.5);
            el.scrollTo(scroll, 0);
          }, 100);
        });
      })
      .catch((error) => {
        console.log('no trades', error);
      });
  };

  getIndicatorPos = () => {
    const { trades } = this.state;
    if (trades.length < 3) return 0;

    let indicator = 2;
    indicator = Math.ceil(trades.length / 2);
    if (indicator % 2 != 0) indicator -= 1;

    return indicator;
  };

  buildTrades = () => {
    const { trades } = this.state;
    let mK = 0;

    return trades.map((trade, i) => {
      if (!trade.oldItem._id) return false;
      if (mK == 4) mK = 0;
      mK++;
      let agoText = 'Unknown';
      if (trade.time) {
        agoText = <TimeAgo date={trade.time} />;
      }
      return (
        <div className={`trade-container item-${mK} item-t-${i}`} key={i}>
          <div className="trade">
            <div className="player">
              <AvatarSmall player={trade.player} />
              <div className="leftBy">
                Left by
                <span className="colour-orange bold">{trade.player.name}</span>
                <span className="colour-opaque">{agoText}</span>
              </div>
            </div>
            <div className="item">
              <img src={trade.oldItem.asset} />
            </div>
          </div>
        </div>
      );
    });
  };

  onScrollStart = () => {
    const { scrollStarted } = this.state;
    if (!scrollStarted) this.setState({ scrollStarted: true });
  };

  render() {
    const { scrollStarted } = this.state;
    const { marker } = this.props;
    const { character } = marker;
    const charText = character.tradeListText
      ? character.tradeListText
      : 'Look at all the gifts I’ve been given! Can you spot yours?';
    const scrollerClass = scrollStarted ? 'scroll-started' : '';
    return (
      <div>
        <ScrollContainer className={`tradesList-scroller`}>
          <div className="tradesList-container">{this.buildTrades()}</div>
        </ScrollContainer>
        <div className={`scroll-indicator fadeInUp ${scrollerClass}`} />
        <div className="speech-container guardian">
          <div className={`speech n-1`}>
            <div
              className="inside"
              dangerouslySetInnerHTML={{ __html: charText }}
            ></div>
          </div>
        </div>

        <div className="button-container bottom">
          <Link to="/customise" className={`button`}>
            Continue
          </Link>
        </div>
      </div>
    );
  }
}
