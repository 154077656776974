/* eslint-disable prettier/prettier */
import React from 'react';
import { Link } from 'react-router-dom';

export default class MainAdminHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '/admin/users',
    };
  }

  renderItems = () => {
    const links = [
      { to: '/admin/users', title: 'Users' },
      { to: '/admin/items', title: 'Items' },
      { to: '/admin/guardians', title: 'Guardians' },
      { to: '/admin/maps', title: 'Maps' },
      { to: '/admin/codes', title: 'QR Codes' },
      { to: '/admin/players', title: 'Players' },
      { to: '/admin/settings', title: 'Settings' },
    ];

    const { selected } = this.state;
    return links.map((item, i) => {
      const linkClass = selected == item.to ? 'selected' : '';
      return (
        <Link
          to={item.to}
          key={i}
          className={`${linkClass}`}
          onClick={() => {
            this.switchLink(item);
          }}
        >
          {item.title}
        </Link>
      );
    });
  };

  switchLink = (item) => {
    this.setState({ selected: item.to });
  };

  render() {
    return <div className="adminHeader">{this.renderItems()}</div>;
  }
}
