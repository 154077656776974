/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import MultiObjectSelector from '@components/MultiObjectSelector/MultiObjectSelector';
import MediaBrowser from '@pages/Admin/Media/components/MediaBrowser/MediaBrowser';
import React from 'react';
import { Redirect } from 'react-router';
import client from '../../../../core/feathers';
import qrcode from 'qrcode-generator';

export default class NewCodePage extends React.Component {
  constructor(props) {
    super(props);
    const edit =
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : false;
    this.state = {
      redirect: false,
      edit,
      formValues: {
        name: '',
        asset: false,
        character: null,
        published: false,
      },
      notFound: false,
      loading: edit ? true : false,
      saved: false,
    };
  }

  componentDidMount() {
    const { edit } = this.state;
    if (edit) {
      this.loadObject();
      this.generateQRcode();
    }
  }

  generateQRcode = () => {
    const { edit } = this.state;
    const typeNumber = 0;
    const errorCorrectionLevel = 'L';
    const qr = qrcode(typeNumber, errorCorrectionLevel);
    const uri = window.location.origin + '/play/' + edit;
    qr.addData(uri);
    qr.make();
    this.setState({ qrCodeGenerated: qr.createImgTag(6), qrCodeUri: uri });
  };

  loadObject = () => {
    const { edit } = this.state;
    const items = client.service('codes');
    Promise.all([items.get(edit)])
      .then(([itemData]) => {
        const item = itemData;
        // Once both return, update the state
        this.setState({ formValues: item, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false, notFound: true });
      });
  };

  setValue = (type, value) => {
    let { formValues } = this.state;
    formValues[type] = value;
    this.setState({ formValues });
  };

  checkValues = (vals) => {
    if (!vals.name || vals.name == '') return false;

    return true;
  };

  save = () => {
    const { edit } = this.state;
    if (edit) return this.update();

    return this.create();
  };

  update = () => {
    const { formValues } = this.state;
    if (!this.checkValues(formValues)) return false;
    return client
      .service('codes')
      .update(formValues._id, formValues)
      .then(() => {
        //reset
        this.setState({
          saved: true,
        });
      });
  };

  create = () => {
    const { formValues } = this.state;
    if (!this.checkValues(formValues)) return false;
    return client
      .service('codes')
      .create(formValues)
      .then(() => {
        //reset
        this.setState({
          redirect: true,
        });
      });
  };

  updateCharacter = (items, refOb) => {
    console.log(items);
    const { formValues } = this.state;
    formValues.character = items;
    this.setState({ formValues });
  };

  render() {
    const {
      formValues,
      redirect,
      loading,
      notFound,
      edit,
      saved,
      qrCodeGenerated,
      qrCodeUri,
    } = this.state;
    if (redirect) {
      return <Redirect to="/admin/codes" />;
    }
    if (notFound) {
      return <div className="notFound">404 not found</div>;
    }
    if (loading) {
      return <div className="loading">Loading...</div>;
    }
    return (
      <div>
        {edit && <h1>Edit {formValues.name}</h1>}
        {qrCodeGenerated && (
          <div className="qr-holder">
            <div
              id="qrcode"
              dangerouslySetInnerHTML={{ __html: qrCodeGenerated }}
            />
            <div className="url">{qrCodeUri}</div>
          </div>
        )}
        {!edit && <h1>Add New Code</h1>}
        {saved && <div className="formNote positive">Item saved</div>}
        <div className="form">
          <div className="formItem">
            <label htmlFor="name">Item Name</label>
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={(e) => {
                this.setValue('name', e.target.value);
              }}
            />
          </div>
          <div className="formItem">
            <label>
              Published?
              <input
                name="published"
                type="checkbox"
                checked={formValues.published}
                onChange={(e) => {
                  this.setValue('published', e.target.checked);
                }}
              />
            </label>
          </div>
          <div className="formItem">
            <label htmlFor="name">Selected Character</label>
            <MultiObjectSelector
              type="characters"
              items={formValues.character}
              updateItems={this.updateCharacter}
              singular={true}
            />
          </div>
        </div>
        <div className="formItem formSubmit">
          <button onClick={this.save}>Save</button>
        </div>
      </div>
    );
  }
}
