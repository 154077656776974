/* eslint-disable prettier/prettier */
import Logo from '@components/Logo/Logo';
import React from 'react';
import { Link } from 'react-router-dom';
import { PageNotFoundContainer } from './PageNotFound.styled';

export const PageNotFound = () => (
  <PageNotFoundContainer data-testid="PageNotFound">
    <div className="404 page page404">
      <Logo isSticky={false} />
      <div className="img404">
        <h2 className="krana padding-none margin-none padding-top">
          That&lsquo;s not one of ours!
        </h2>
        <img src="/images/error-404.svg" width="70%" />
        <p>
          Sorry, we don&lsquo;t recognise that QR code. Does it have the hide n
          find logo on it?
        </p>
      </div>
      <div className="button-container bottom fadeInUp">
        <Link className="button" to="/play">
          Back
        </Link>
      </div>
    </div>
  </PageNotFoundContainer>
);
