/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable prettier/prettier */
import Logo from '@components/Logo/Logo';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import './MenuPage.scss';
import { isPlayer, loadPlayer } from '../../functions/players';
export default class MenuPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
      markerFound: false,
    };
  }

  componentDidMount() {
    this.getPlayer();
  }

  getPlayer = () => {
    const player = isPlayer();
    if (!player) {
      this.onboard();
      return console.log('not logged in (no player found)');
    }
    loadPlayer((p) => {
      if (!p) return console.log('error fetching player');
      this.setState({ player: p });
    });
  };

  onboard = () => {
    this.setState({ redirect: `/` });
  };

  newGame = () => {
    localStorage.clear();
    this.setState({ redirect: '/' });
  };

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <div className="page-menu-container">
        <div className="page-menu page background full">
          <Logo sticky={false} />
          <div className="button-container left" style={{ zIndex: 13 }}>
            <Link className="button green no-text right" to="/help">
              <span className="info-white" />
            </Link>
          </div>
          <div className="scan-one-of-these fadeInUpRotate10 delay-2">
            <img src="/images/home-scan-one-of-these.svg" width="100%" />
          </div>
          <Link to="/play" className="scanner-btn fadeInUp delay-1">
            <div className="button white start-scanner">Scan it!</div>
            <div className="flowers-overlay fadeInUpScale delay-4"></div>
          </Link>
          <Link
            to="/customise"
            className="customise-btn font-25 fadeInUp delay-2"
          >
            <div className="customise-hat fadeInUp delay-3"></div>
            <div className="btn-inside">My Adventures</div>

            <div className="customise-moustache fadeInUpRotate10 delay-6"></div>
          </Link>
          <Link
            to="/zones"
            className="customise-btn zones-btn font-25 fadeInUp delay-4"
          >
            <div className="btn-inside">Map</div>
            <div className="zones-map-img fadeInUpRotate10 delay-8"></div>
          </Link>
          <Link
            to="/parents"
            className="button white parents padding-normal padding-20 fadeInUp delay-3"
          >
            <div className="flex justify-center align-center">
              <div className="grow dm-sans font-16 text-transform-none text-align-left">
                <div className="bold">Grown ups click here!</div>
              </div>
              <div className="flex justify-center align-center">
                <span className="info"></span>
              </div>
            </div>
          </Link>
          <div className="content-area white">
            <div className="p">
              <div className="bold">Are you a parent?</div>
              <div className="weight-none">
                <Link to="/parents">More info about this game</Link>
              </div>
            </div>
            <div className="p">
              <div className="bold">Want to start a new game?</div>
              <div className="weight-none">
                <button
                  className="none transparent link"
                  onClick={() => {
                    if (
                      window.confirm(
                        'Starting a new game will wipe any games in progress, are you sure?'
                      )
                    )
                      this.newGame();
                  }}
                >
                  New game
                </button>
              </div>
            </div>
            <div className="p">
              <div className="bold">Privacy</div>
              <div className="weight-none">
                This site requires the use of the device camera.
                <br />
                Game progress is stored in a cookie.
                <br />
                Read our{' '}
                <a href="https://wentworthwoodhouse.org.uk/cookie-policy/">
                  Cookie Policy
                </a>
              </div>
            </div>
            <div className="ww-logo">
              <div className="weight-none">
                <Link to="/parents">
                  <img src="/images/Hide-and-Find-Logos.svg" />
                </Link>
              </div>
            </div>
            <div className="version-menu">V{process.env.REACT_APP_VERSION}</div>
          </div>
        </div>
      </div>
    );
  }
}
