/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import Asset from '@components/Asset/Asset';
import React from 'react';

export default class FoundStage4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: { _id: null },
    };
  }

  trade = () => {
    //trade the item!
    const { selected } = this.state;
    if (!selected._id) return;
    this.props.trade(selected);
  };

  buildTradeables = () => {
    const { selected } = this.state;
    const { player } = this.props;
    return player.tradeable.map((item, i) => {
      let isSelected = selected._id == item._id ? 'selected' : '';
      if (selected._id == null) isSelected = 'full';
      return (
        <div className={`tradeable ${isSelected}`} key={i}>
          <button
            onClick={() => {
              this.select(item, i);
            }}
          >
            <Asset src={item.asset} />
          </button>
        </div>
      );
    });
  };

  select = (item, i) => {
    this.setState({ selected: item });
  };

  render() {
    const { marker } = this.props;
    const { selected } = this.state;
    const buttonDisabled = !selected._id ? 'disabled' : '';
    return (
      <>
        <div className="tradeChoosePage">
          <div className="heading">
            Choose one of your <span className="colour-orange bold">gifts</span>{' '}
            to swap with{' '}
            <span className="colour-orange bold">{marker.character.name}</span>.
          </div>
          <div className="itemsSelector">{this.buildTradeables()}</div>
        </div>
        <div className="button-container bottom">
          <button className={`button ${buttonDisabled}`} onClick={this.trade}>
            Continue
          </button>
        </div>
      </>
    );
  }
}
