/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import './Character.scss';
export default class Character extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { marker, visible, size, isSticky, className } = this.props;
    const visibleClass = visible ? 'show' : 'hide';
    const stickyClass = isSticky ? 'sticky' : 'static';
    return (
      <div
        className={`character z-100 spin ${visibleClass} size-${size} ${stickyClass} ${className}`}
        id="character"
      >
        <div className="burst-lines" />
        <img src={marker.character.asset} />
      </div>
    );
  }
}
