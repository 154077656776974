/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';

export default class Logo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { colour, sticky } = this.props;
    let img = '/images/hide-n-find-logo-white.svg';
    if (colour && colour == 'purple')
      img = '/images/hide-n-find-logo-purple.svg';

    let isSticky = true;
    if (!sticky) isSticky = false;
    const stickyClass = isSticky ? 'sticky' : 'static';
    return (
      <div className={`logo ${stickyClass}`}>
        <img src={img} width="80" />
      </div>
    );
  }
}
