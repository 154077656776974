/* eslint-disable prettier/prettier */
import Populate from './populate';

export default function PopulateMarker(marker, done) {
  Populate(marker.character, (character) => {
    marker.character = character;
    Populate(marker.character.items, (items) => {
      marker.character.items = items;
      done(marker);
    });
  });
}
