/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Redirect } from 'react-router';
import { isPlayer, setPlayer, loadPlayer } from '../../../functions/players';
import NameGenerator from '@components/NameGenerator/NameGenerator';
import './InitialPage.scss';
import { Link } from 'react-router-dom';
import client from '../../../core/feathers';
import getRandomFromArray from '../../../functions/getRandomFromArray';
import makeRefs from '../../../functions/makeRefs';
import Logo from '@components/Logo/Logo';
import Asset from '@components/Asset/Asset';
import PageNotFound from '@pages/PageNotFound';
import HelpCarousel from '@pages/Standard/HelpCarousel';

export default class InitialPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      redirect: false,
      slide: 0,
      initialName: '',
      items: [],
      onboardPlay: localStorage.getItem('onboard-play'),
      area: props.match.params.id ? props.match.params.id : false,
    };
  }

  componentDidMount() {
    const { area } = this.state;
    if (isPlayer()) {
      this.setState({ loading: false, redirect: true }, () => {
        loadPlayer();
      });
    } else {
      if (!area) {
        this.setState({ loading: false, redirect: false }, () => {
          //get a bunch of random items in the background
          this.getRandomItems();
        });
      } else {
        this.getArea();
      }
    }
  }

  getArea = () => {
    const { area } = this.state;
    client
      .service('maps')
      .find({ query: { uri: area } })
      .then((map) => {
        if (map.data.length <= 0) {
          this.setState({ loading: false, mapError: true });
          return;
        }
        this.setState({ areaMap: map.data[0], loading: false }, () => {
          localStorage.setItem('_startMap', map.data[0]._id);
          this.getRandomItems();
        });
      })
      .catch((error) => {
        this.setState({ loading: false, mapError: true });
      });
  };

  getRandomItems = () => {
    client
      .service('items')
      .find({
        query: {
          inPool: true,
        },
      })
      .then((data) => {
        this.setState({ items: this.getRandomLimit(data.data) }, () => {
          console.log(this.state);
        });
      });
  };

  getRandomLimit = (items) => {
    const limit = 6;
    if (items.length <= limit) {
      return items;
    }

    return getRandomFromArray(items, limit);
  };

  buildItems = () => {
    const { player } = this.state;
    if (!player || !player.tradeable) return [];
    return player.tradeable.map((item, i) => {
      const d = i + 2;
      return (
        <div className={`tradeable view fadeInUpScale delay-${d}`} key={i}>
          <div className="tradeable-container">
            <Asset src={item.asset} />
          </div>
        </div>
      );
    });
  };

  onBoard = () => {
    //create player
    const { initialName, items, area } = this.state;
    const areasUnlocked = [];
    if (area) areasUnlocked.push(area._id);
    client
      .service('players')
      .create({
        name: initialName,
        tradeable: items,
        trades: [],
        found: [],
        avatar: null,
        unlocked: areasUnlocked,
      })
      .then((player) => {
        setPlayer(player);
        this.setState({ slide: 2, player });
      });
  };

  getPlayerName = () => {
    const { player } = this.state;
    if (!player) return '';

    return player.name;
  };

  render() {
    const {
      redirect,
      loading,
      onboardPlay,
      area,
      mapError,
      areaMap,
    } = this.state;
    if (loading) {
      return <div className="loader">Loading...</div>;
    }
    if (redirect) {
      return <Redirect to="/menu" />;
    }

    const { slide } = this.state;
    let slideOnboardClass = 'hide';
    let slide1Class = slide == 0 ? 'show' : 'hide';
    const slide2Class = slide == 1 ? 'show' : 'hide';
    const slide3Class = slide == 2 ? 'show' : 'hide';
    const slide4Class = slide == 3 ? 'show' : 'hide';
    const slide5Class = slide == 4 ? 'show' : 'hide';

    const areaClass = area && slide == 0 ? 'show' : 'hide';
    if (area && slide == 0) {
      slide1Class = 'hide';
    }

    if (onboardPlay && slide == 0) {
      slide1Class = 'hide';
      slideOnboardClass = 'show';
    }

    if (mapError) {
      return <PageNotFound />;
    }

    return (
      <div className="initialPageContainer background">
        <div
          className={`initialPage page-onboard page no-logo full slide1 slide ${slide1Class} first-slide`}
        >
          <div className="slanted onboard-heading text-transform-upper padding-top padding-bottom">
            <div className="font-40 krana lh-40 fadeInUp">Hello</div>
            <div className="font-56 krana lh-40 fadeInUp delay-1">
              Adventurer
            </div>
            <div className="font-20 krana lh-20 fadeInUp delay-2">
              Welcome To
            </div>
          </div>
          <div className="anim-burst-lines" />
          <div className="onboard-logo fadeInUp delay-3" />
          <div className="onboard-house fadeInUp delay-4" />
          <div className="onboard-flag fadeInUp delay-5" />

          <div className="button-bottom button-container bottom">
            <button
              className="button fadeInUp delay-6"
              onClick={() => {
                this.setState({ slide: 1 });
              }}
            >
              Go!
            </button>
          </div>
        </div>
        {area && (
          <div
            className={`initialPage page-onboard page no-logo full slide1 slide no-padding ${areaClass}`}
          >
            <div className="slanted onboard-heading text-transform-upper padding-top padding-bottom">
              <div className="font-40 krana lh-40 fadeInUp">Hello</div>
              <div className="font-56 krana lh-40 fadeInUp delay-1">
                Adventurer
              </div>
              <div className="font-20 krana lh-20 fadeInUp delay-2">
                Welcome To
              </div>
            </div>
            <div className="anim-burst-lines fadeInUpScale delay-8 onboard-lines" />

            <div className={`onboard-area fadeInUpScale delay-3`}>
              <img src={areaMap.initialAsset} />
            </div>
            <div className="button-bottom button-container bottom">
              <button
                className="button fadeInUp delay-6"
                onClick={() => {
                  this.setState({ slide: 1 });
                }}
              >
                Start
              </button>
            </div>
          </div>
        )}
        <div
          className={`initialPage page-onboard page no-logo full slide1 onboardSlide slide ${slideOnboardClass} background lightOrange`}
        >
          <div className="onboard-heading text-transform-upper padding-top">
            <div className="font-20 krana lh-20 fadeInUp">Welcome To</div>
          </div>
          <div className="onboard-logo fadeInUp delay-1" />
          <div className="onboard-house fadeInUp delay-2" />
          <div className="onboard-flag fadeInUp delay-3" />
          <div className="onboard-content font-18 colour-purple">
            <p className="bold font-20">
              A game for 7-11 year olds to help them explore the grounds of
              Wentworth Woodhouse!
            </p>
            <Link
              to="/parents"
              className="button white parents padding-normal padding-20"
            >
              <div className="flex justify-center">
                <div className="grow dm-sans font-16 text-transform-none text-align-left">
                  <div className="bold">Are you an adult?</div>
                  <div className="weight-none">
                    Click here for more info about this site
                  </div>
                </div>
                <div className="flex justify-center align-center">
                  <span className="info"></span>
                </div>
              </div>
            </Link>
            <p>
              Discover the Guardians of Wentworth and see what they’re hiding in
              this free mobile experience.
            </p>
            <p>
              Find and scan the QR codes hidden throughout the grounds to
              uncover the stories within and customise your avatar with
              characters from history.
            </p>
            <p>
              This site uses cookies to save your progress in the game, and
              requires access to the camera in order to scan the QR codes, we
              don’t store any unique or personal information throughout the
              experience.
            </p>
            <p>
              For more information, please visit the about this site page from
              the main Home Screen.
            </p>
          </div>
          <div className="button-bottom button-container bottom">
            <button
              className="button"
              onClick={() => {
                this.setState({ slide: 1 });
              }}
            >
              Continue
            </button>
          </div>
        </div>
        <div className={`initialPage page full slide2 slide ${slide2Class}`}>
          <Logo />
          <div className="pick-name fadeInUp">
            Let's pick a name for you!
            <NameGenerator
              onChange={(initialName) => {
                this.setState({ initialName });
              }}
            />
          </div>
          <div className="button-container bottom">
            <p className="fadeInUpScale delay-1">
              When you're happy, press GO!
            </p>
            <button onClick={this.onBoard} className="button fadeInUp delay-2">
              GO!
            </button>
          </div>
        </div>
        <div className={`initialPage page full slide3 slide ${slide3Class}`}>
          <Logo />
          <div className="onboard-cropped">
            <img src="/images/avatar-defaul-cropped.svg" />
          </div>
          <div className="hello-name krana fadeInUp">
            Hello
            <div className="font-40 text-transform-upper">
              {this.getPlayerName()}
            </div>
          </div>
          <div className="button-container bottom">
            <p className="fadeInUpScale delay-1">
              Let's go find some
              <br />
              <span className="colour-orange bold">Guardians</span>!
            </p>
            <button
              onClick={() => {
                this.setState({ slide: 3 });
              }}
              className="button fadeInUp delay-2"
            >
              Lets Go!
            </button>
          </div>
        </div>
        <div className={`initialPage page full slide4 slide ${slide4Class}`}>
          <Logo />
          <div className="onboard-items">
            <div className="bag-dig fadeInUp"></div>
            <div className="text fadeInUp delay-1">
              Swap these <span className="colour-orange bold">gifts</span> with
              guardians you find
            </div>
            <div className="items itemsSelector padding-top">
              {this.buildItems()}
            </div>
          </div>
          <div className="button-container bottom">
            <button
              onClick={() => {
                this.setState({ slide: 4 });
              }}
              className="button fadeInUp delay-2"
            >
              Continue
            </button>
          </div>
        </div>
        <div
          className={`initialPage page no-logo full slide4 slide ${slide5Class}`}
        >
          <div className="onboard-scroller">
            <div className="whitePageInside no-margin">
              <h2>About Hide & Find</h2>
              <HelpCarousel />
            </div>
          </div>
          <div className="button-container bottom no-fixed">
            {!onboardPlay && (
              <Link className="button" to="/menu">
                Continue
              </Link>
            )}
            {onboardPlay && (
              <Link className="button" to={`/play/${onboardPlay}`}>
                Continue
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}
