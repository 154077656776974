import React from 'react';
import client from '../../../core/feathers';
import EditUser from './components/Edit';
import UserList from './components/List';
import NewUser from './components/New';
class UsersPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      users: [],
      editUser: false,
    };
  }

  componentDidMount() {
    this.updateUserList();
    const users = client.service('users');
    users.on('update', () => {
      this.updateUserList();
    });
    users.on('patch', () => {
      this.updateUserList();
    });
    users.on('remove', () => {
      this.updateUserList();
    });
  }

  onUserSave = () => {
    this.setState({ editUser: false });
  };

  updateUserList = () => {
    const users = client.service('users');
    Promise.all([users.find()]).then(([userPage]) => {
      // We want the latest messages but in the reversed order
      const users = userPage.data;
      // Once both return, update the state
      this.setState({ users });
    });
  };

  render() {
    const { users, editUser } = this.state;
    return (
      <div className="users">
        <UserList
          users={users}
          onEdit={(u: any) => {
            this.setState({ editUser: u });
          }}
        />
        <NewUser />
        {editUser && <EditUser user={editUser} onSave={this.onUserSave} />}
      </div>
    );
  }
}

export default UsersPage;
