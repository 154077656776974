/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import client from '../../../../core/feathers';

export default class EditUser extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      notice: false,
      email: props.user.email,
      show: false,
    };
  }

  updateField(name, ev) {
    this.setState({ [name]: ev.target.value });
  }

  save() {
    const { email, password, password_repeat } = this.state;
    let d;
    if (password && password != '') {
      if (password != password_repeat) {
        //error
        //throw some error
      } else {
        d = { email, password };
      }
    } else {
      d = { email };
    }

    return client
      .service('users')
      .patch(d)
      .then(() => {
        //reset
        this.setState(
          {
            email: '',
            password: '',
            notice: { message: 'User Saved!' },
          },
          () => {
            this.props.onSave();
          }
        );
      });
  }

  render() {
    const { email } = this.state;
    return (
      <main className="login container">
        <div className="row">
          <div className="col-12 col-6-tablet push-3-tablet text-center heading">
            <h1 className="font-100">Create a user</h1>
            <p>{this.state.notice.message && this.state.notice.message}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-6-tablet push-3-tablet col-4-desktop push-4-desktop">
            <form className="form">
              <fieldset>
                <input
                  className="block"
                  type="email"
                  name="email"
                  placeholder="email"
                  value={email}
                  onChange={(ev) => this.updateField('email', ev)}
                />
              </fieldset>

              <fieldset>
                <input
                  className="block"
                  type="password"
                  name="password"
                  placeholder="password"
                  onChange={(ev) => this.updateField('password', ev)}
                />
              </fieldset>
              <fieldset>
                <input
                  className="block"
                  type="password_repeat"
                  name="password_repeat"
                  placeholder="password repeat"
                  onChange={(ev) => this.updateField('password_repeat', ev)}
                />
              </fieldset>

              <button
                type="button"
                className="button button-primary block signup"
                onClick={() => this.save()}
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </main>
    );
  }
}
