/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import Asset from '@components/Asset/Asset';
import React from 'react';

export default class FoundStage1 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { marker } = this.props;
    return (
      <>
        <div className="itemFound newItem">
          <div className="starContainer fadeInUp">
            <div className="star rotateSlow"></div>
          </div>
          <div style={{ marginTop: '30px' }}>
            <div className="heading krana font-40 lh-40 fadeInUpRotate5 delay-1">
              Well Done
              <span className="font-56 fadeInUp delay-2">Adventurer!</span>
            </div>
            <div className="fadeInUpScale delay-3">
              <Asset src={marker.character.asset} />
            </div>
            <div className="subHeading fadeInUpScale delay-4">
              You have found a guardian!
            </div>
            <div className="button-container bottom no-fixed">
              <button className="button" onClick={this.props.step}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
