/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import ObjectList from '@components/ObjectList/ObjectList';
import React from 'react';
import { Link } from 'react-router-dom';

export default class MapsPage extends React.Component {
  render() {
    return (
      <div>
        <Link to="/admin/maps/new" className="button smallest">
          + Add New Map
        </Link>
        <ObjectList
          type="maps"
          options={[
            {
              title: 'Edit',
              type: 'link',
              link: '/admin/maps/{id}',
            },
            {
              title: 'Delete',
              type: 'delete',
            },
          ]}
        />
      </div>
    );
  }
}
