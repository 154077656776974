/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import Asset from '@components/Asset/Asset';
import React from 'react';

export default class FoundStage2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };

    this.script = [
      'Some text script 1',
      'some more text from script 2',
      'a third line of text to seal the scripting deal',
    ];
  }

  getStyle = () => {
    const padding = document.getElementById('character').offsetHeight - 30;
    return {
      paddingBottom: `${padding}px`,
    };
  };

  next = () => {
    const { index } = this.state;
    const script = this.getScript();
    if (index + 1 > script.length - 1) return this.props.step();

    this.setState({ index: index + 1 }, () => {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      }, 450);
    });
  };

  getText = () => {
    const { index } = this.state;
    const { marker } = this.props;
    const script = this.getScript();
    let pos = 0;
    return script.map((item, n) => {
      pos += 1;
      if (pos > 3) {
        pos = 0;
      }
      let txt = item;
      let isUser = false;
      if (typeof item != 'string') {
        txt = item.text;
        if (item.isUser) isUser = true;
      }

      const isUserClass = isUser ? 'user' : 'character-bubble';
      let arrowClass = '';
      console.log(isUser, index, n, script[n + 1]);
      if (!isUser && index >= n + 1 && !script[n + 1].isUser) {
        //don't show the arrow
        arrowClass = 'noArrow';
      }

      txt = txt.replace(
        '{item}',
        "<img src='" + marker.character.items[0].asset + "' />"
      );

      if (n <= index) {
        return (
          <div
            className={`speech n-${pos} ${isUserClass} ${arrowClass}`}
            key={n}
          >
            <div className="inside" dangerouslySetInnerHTML={{ __html: txt }} />
          </div>
        );
      }
    });
  };

  getScript = () => {
    const { marker } = this.props;

    let script = [
      "Look at all those fancy <span class='colour-orange bold'>gifts</span> you've got!",
      "I'll let you keep my<div><img src='" +
        marker.character.items[0].asset +
        "' /></div>",
      "If you're willing to trade one of your <span class='colour-orange bold'>gifts</span>?",
    ];

    if (!marker.character.preTradeScript) return script;
    if (
      marker.character.preTradeScript &&
      marker.character.preTradeScript.length <= 0
    )
      return script;

    return marker.character.preTradeScript;
  };

  render() {
    const { marker } = this.props;
    return (
      <div className="speech-container" style={this.getStyle()}>
        {this.getText()}
        <div className="button-container bottom">
          <button className="button" onClick={this.next}>
            Continue
          </button>
        </div>
      </div>
    );
  }
}
