/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import client from '../core/feathers';

/* eslint-disable @typescript-eslint/no-unused-vars */
export default function Populate(items, func) {
  if (!Array.isArray(items)) {
    //it's singular
    const type = items['$ref'];
    client
      .service(type)
      .get(items['$id'])
      .then((data) => {
        func(data);
      })
      .catch((error) => console.log(error));
    return;
  }

  const _items = [];
  if (items.length <= 0 || !items) return _items;

  const type = items[0]['$ref'];

  const ids = items.map((item, i) => item['$id']);

  client
    .service(type)
    .find({
      query: {
        _id: {
          $in: ids,
        },
      },
    })
    .then((data) => {
      func(data.data);
    })
    .catch((error) => console.log(error));
}
