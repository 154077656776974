/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';

export default class Asset extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="asset">
        <img src={this.props.src} />
      </div>
    );
  }
}
