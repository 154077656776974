/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import { getPlayerAvatar } from '../../functions/players';

export default class AvatarSmall extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { player } = this.props;
    const avatar = getPlayerAvatar(player);
    return (
      <div className="avatar avatar-small">
        <img src={avatar.characterObj.assetAvatarSmall} />
      </div>
    );
  }
}
