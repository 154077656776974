/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import { getPlayerName } from '../../functions/players';

export default class NameGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: getPlayerName(),
    };
  }

  componentDidMount() {
    this.passBack();
  }

  change = () => {
    this.setState({ name: getPlayerName() }, () => {
      this.passBack();
    });
  };

  passBack = () => {
    this.props.onChange(this.state.name);
  };

  render() {
    const { name } = this.state;
    return (
      <div className="name-generator">
        <div className="input-text krana">{name}</div>
        <button className="button-clear" onClick={this.change}>
          <span className="btn-refresh"></span>
        </button>
      </div>
    );
  }
}
