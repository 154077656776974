import React, { Component } from 'react';
import client from '../../../../core/feathers';

export default class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notice: false,
    };
  }

  updateField(name, ev) {
    this.setState({ [name]: ev.target.value });
  }

  login() {
    const { email, password } = this.state;

    return client
      .authenticate({
        strategy: 'local',
        email,
        password,
      })
      .catch((error) => this.setState({ error }));
  }

  signup() {
    const { email, password } = this.state;

    return client
      .service('users')
      .create({ email, password })
      .then(() => {
        //reset
        this.setState({
          email: '',
          password: '',
          notice: { message: 'User Created!' },
        });
      });
  }

  render() {
    return (
      <main className="login container">
        <div className="row">
          <div className="col-12 col-6-tablet push-3-tablet text-center heading">
            <h1 className="font-100">Create a user</h1>
            <p>{this.state.notice.message && this.state.notice.message}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-6-tablet push-3-tablet col-4-desktop push-4-desktop">
            <form className="form">
              <fieldset>
                <input
                  className="block"
                  type="email"
                  name="email"
                  placeholder="email"
                  onChange={(ev) => this.updateField('email', ev)}
                />
              </fieldset>

              <fieldset>
                <input
                  className="block"
                  type="password"
                  name="password"
                  placeholder="password"
                  onChange={(ev) => this.updateField('password', ev)}
                />
              </fieldset>

              <button
                type="button"
                className="button button-primary block signup"
                onClick={() => this.signup()}
              >
                Create User
              </button>
            </form>
          </div>
        </div>
      </main>
    );
  }
}
