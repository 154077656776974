/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import Asset from '@components/Asset/Asset';
import React from 'react';
import { getPlayerAvatar } from '../../functions/players';
import AllFound from '@components/AllFound/AllFound';

import './AvatarPage.scss';
export default class AvatarPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { show, player, close } = this.props;
    const avatar = getPlayerAvatar(player);
    const showClass = show ? 'show' : 'hide';

    return (
      <>
        <AllFound />
        <div
          className={`page-avatar ${showClass}`}
          style={{
            backgroundImage: `url(${avatar.characterObj.assetAvatarScene})`,
          }}
        >
          <div className="button-container left" style={{ zIndex: 99 }}>
            <button onClick={close} className="button green no-text right">
              <span className="close" />
            </button>
          </div>
          <div className="avatar">
            <Asset src={avatar.characterObj.assetAvatarLarge} />
          </div>
          <div className="avatar-content">
            <div className="title">
              You are currently dressed like{' '}
              <span className="colour-orange bold">
                {avatar.characterObj.name}
              </span>
            </div>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: avatar.characterObj.infoText }}
            />
          </div>
        </div>
      </>
    );
  }
}
