import io from 'socket.io-client';
import feathers from '@feathersjs/client';

const con =
  process.env.NODE_ENV == 'production'
    ? 'https://hidefind.wentworthwoodhouse.org.uk'
    : 'http://localhost:3030';

const socket = io(con);
const client = feathers();

client.configure(feathers.socketio(socket));
client.configure(
  feathers.authentication({
    storage: window.localStorage,
  })
);

export default client;
