/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import client from '../../core/feathers';

export default class ObjectBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.type,
      objects: [],
    };
  }

  componentDidMount() {
    this.find();
  }

  componentDidUpdate(prevProps) {
    //render based on props
    if (prevProps.type != this.props.type) {
      this.setState({ type: this.props.type }, () => {
        //type changed
        this.find();
      });
    }
  }

  find = () => {
    const { type } = this.state;
    const obs = client.service(type);
    Promise.all([obs.find()]).then(([obsData]) => {
      // Once both return, update the state
      this.setState({ objects: obsData.data });
    });
  };

  getObjectTitle = (object) => {
    if (object.name) return object.name;
    if (object.email) return object.email;
    if (object.originalName) return object.originalName;
    return 'undefined';
  };

  getObjectAsset = (object) => {
    if (object.asset) return object.asset;
    if (object.url) return object.url;
    return false;
  };

  buildContents = () => {
    const { objects } = this.state;
    if (objects.length <= 0) return [];

    return objects.map((object, index) => {
      const asset = this.getObjectAsset(object);
      return (
        <button
          className="object-selectable"
          key={index}
          onClick={() => {
            this.selectObject(object);
          }}
        >
          <div className="inside">
            <span className="object-name">{this.getObjectTitle(object)}</span>
            {asset && (
              <div className="object-asset">
                <img src={asset} />
              </div>
            )}
          </div>
        </button>
      );
    });
  };

  selectObject = (object) => {
    this.props.select(object);
    this.props.close();
  };

  render() {
    const { show } = this.props;
    const showClass = show ? 'show' : 'hide';
    return (
      <>
        <div className={`objectBrowser ${showClass}`}>
          <button className="ob-bg" onClick={this.props.close} />
          <div className="ob-content">
            <div className="ob-content--header">Object Browser</div>
            <div className="ob-content--content">{this.buildContents()}</div>
          </div>
        </div>
      </>
    );
  }
}
