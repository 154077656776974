/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import MediaBrowser from '@pages/Admin/Media/components/MediaBrowser/MediaBrowser';
import React from 'react';
import { Redirect } from 'react-router';
import client from '../../../../core/feathers';

export default class NewItemPage extends React.Component {
  constructor(props) {
    super(props);
    const edit =
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : false;
    this.state = {
      showBrowser: false,
      redirect: false,
      edit,
      formValues: {
        name: '',
        asset: false,
        inPool: false,
        published: false,
      },
      notFound: false,
      loading: edit ? true : false,
      saved: false,
    };
  }

  componentDidMount() {
    const { edit } = this.state;
    if (edit) this.loadObject();
  }

  loadObject = () => {
    const { edit } = this.state;
    const items = client.service('items');
    Promise.all([items.get(edit)])
      .then(([itemData]) => {
        const item = itemData;
        // Once both return, update the state
        this.setState({ formValues: item, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false, notFound: true });
      });
  };

  setValue = (type, value) => {
    let { formValues } = this.state;
    formValues[type] = value;
    this.setState({ formValues });
  };

  showMediaBrowser = () => {
    this.setState({ showBrowser: true });
  };

  hideMediaBrowser = () => {
    this.setState({ showBrowser: false });
  };

  checkValues = (vals) => {
    if (!vals.asset || !vals.name || vals.name == '') return false;

    return true;
  };

  save = () => {
    const { edit } = this.state;
    if (edit) return this.update();

    return this.create();
  };

  update = () => {
    const { formValues } = this.state;
    if (!this.checkValues(formValues)) return false;
    return client
      .service('items')
      .update(formValues._id, formValues)
      .then(() => {
        //reset
        this.setState({
          saved: true,
        });
      });
  };

  create = () => {
    const { formValues } = this.state;
    if (!this.checkValues(formValues)) return false;
    return client
      .service('items')
      .create(formValues)
      .then(() => {
        //reset
        this.setState({
          redirect: true,
        });
      });
  };

  render() {
    const {
      formValues,
      showBrowser,
      redirect,
      loading,
      notFound,
      edit,
      saved,
    } = this.state;
    if (redirect) {
      return <Redirect to="/admin/items" />;
    }
    if (notFound) {
      return <div className="notFound">404 not found</div>;
    }
    if (loading) {
      return <div className="loading">Loading...</div>;
    }
    return (
      <div>
        {edit && <h1>Edit {formValues.name}</h1>}
        {!edit && <h1>Add New Item</h1>}
        {saved && <div className="formNote positive">Item saved</div>}
        <div className="form">
          <div className="formItem">
            <label htmlFor="name">Item Name</label>
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={(e) => {
                this.setValue('name', e.target.value);
              }}
            />
          </div>
          <div className="formItem">
            <label>
              Include in onboarding pool:
              <input
                name="inPool"
                type="checkbox"
                checked={formValues.inPool}
                onChange={(e) => {
                  this.setValue('inPool', e.target.checked);
                }}
              />
            </label>
          </div>
          <div className="formItem">
            <label>
              Published?
              <input
                name="published"
                type="checkbox"
                checked={formValues.published}
                onChange={(e) => {
                  this.setValue('published', e.target.checked);
                }}
              />
            </label>
          </div>
          <div className="formItem">
            <label htmlFor="name">Selected Asset</label>
            <div className="formItem--asset">
              <div>{formValues.asset && <img src={formValues.asset} />}</div>
              <div>
                <button onClick={this.showMediaBrowser}>change</button>
              </div>
            </div>
            <MediaBrowser
              show={showBrowser}
              hide={this.hideMediaBrowser}
              select={(img) => {
                this.setValue('asset', img.url);
              }}
            />
          </div>
        </div>
        <div className="formItem formSubmit">
          <button onClick={this.save}>Save</button>
        </div>
      </div>
    );
  }
}
